import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/css/bootstrap.min.css';
import 'assets/scss/paper-kit.scss';
import 'assets/demo/demo.css';
import 'assets/demo/react-demo.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// Custom css
import 'views/LandingPage/landing.css';
import './index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';

// ReactDOM.render(<App />, document.getElementById('root'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
reportWebVitals();
