import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components

function SectionContent() {
	return (
		<>
			<div id='fadeInAnim'>
				<div className='section section-content section-gray'>
					<Container>
						<Row>
							<Col md='7'>
								<div className='image-container'>
									<img
										alt='...'
										className='img'
										src={require('../Images/ipad-2.png')}
									/>

									<img
										alt='...'
										className='area-img add-animation'
										src={require('../Images/feed1.jpg')}
									/>
									<img
										alt='...'
										className='info-img add-animation'
										src={require('../Images/ipad-comments.jpg')}
									/>
								</div>
							</Col>
							<Col md='5'>
								<div className='section-description'>
									<h3 className='title'>
										<strong>Join your local community</strong>
									</h3>
									<h6 className='category'>
										YOUR CUSTOMERS ARE ALREADY WAITING FOR YOU!
									</h6>
									<h5 className='description'>
										<strong>
											{' '}
											Pick an apartment building close to your business and
											connect to its residents with ease. Add your loyal
											customers and keep them updated about your business via
											email and{' '}
											<a target='_block' href='https://www.weciny.com/'>
												Weciny!
											</a>
										</strong>
									</h5>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</>
	);
}

export default SectionContent;
