import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postCartItem } from '../../../store/actions/purchase';

// reactstrap components
import {
	Button,
	Card,
	Container,
	Row,
	Col,
	Carousel,
	CarouselItem,
	CarouselIndicators,
} from 'reactstrap';

function ProductPage(rest) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// carousel states and functions
	const [activeIndex, setActiveIndex] = useState(0);
	const [animating, setAnimating] = useState(false);
	const { product, productQuantity, totalItemsInCart } = rest;
	const onExiting = () => {
		setAnimating(true);
	};
	const onExited = () => {
		setAnimating(false);
	};
	const next = () => {
		if (animating) return;
		const nextIndex =
			activeIndex === product.image.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};
	const previous = () => {
		if (animating) return;
		const nextIndex =
			activeIndex === 0 ? product.image.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};
	const goToIndex = (newIndex) => {
		if (animating) return;
		setActiveIndex(newIndex);
	};

	document.documentElement.classList.remove('nav-open');
	useEffect(() => {
		const productPageClass = 'product-page';
		document.body.classList.add(productPageClass);

		return () => {
			document.body.classList.remove(productPageClass);
		};
	}, []);
	// Handle missing product data
	if (!product) {
		return <div>Loading product...</div>;
	}
	const handleAddToCart = () => {
		// Dispatch the action to add the item to the cart
		dispatch(postCartItem(product._id));
		navigate(`/business-page/${product.business._id}`);
	};
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	return (
		<>
			<div className='main'>
				<div className='section'>
					<Container style={{ maxWidth: '90%' }}>
						<Row className='title-row'>
							<Col md='3'>
								<a href={`/business-page/${product.business._id}`}>
									<h4 className='shop'>
										{' '}
										<i className='bi bi-skip-backward-circle' /> Business Page
									</h4>
								</a>
							</Col>
							<Col md='2'>
								<h4 className='shop'>Shop</h4>
							</Col>
							<Col className='ml-auto' md='4'>
								<div className='pull-right'>
									<span className='text-muted'>Order Status</span>
									<Button color='link' href='/cart'>
										<i className='fa fa-shopping-cart' /> {totalItemsInCart}{' '}
										Items
									</Button>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md='6' sm='6'>
								<div className='ml-auto mr-auto' id='carousel'>
									<Card className='page-carousel'>
										<Carousel
											activeIndex={activeIndex}
											next={next}
											previous={previous}>
											<CarouselIndicators
												items={product.image}
												activeIndex={activeIndex}
												onClickHandler={goToIndex}
											/>
											{product.image.map((item, index) => {
												return (
													<CarouselItem
														onExiting={onExiting}
														onExited={onExited}
														key={index}>
														<img src={item} alt={index} />
													</CarouselItem>
												);
											})}
											<a
												className='left carousel-control carousel-control-prev'
												data-slide='prev'
												href='#pablo'
												onClick={(e) => {
													e.preventDefault();
													previous();
												}}
												role='button'>
												<span className='fa fa-angle-left' />
												<span className='sr-only'>Previous</span>
											</a>
											<a
												className='right carousel-control carousel-control-next'
												data-slide='next'
												href='#pablo'
												onClick={(e) => {
													e.preventDefault();
													next();
												}}
												role='button'>
												<span className='fa fa-angle-right' />
												<span className='sr-only'>Next</span>
											</a>
										</Carousel>
									</Card>
								</div>
								{/* end carousel */}
							</Col>
							<Col md='6' sm='6'>
								<h2>{product.title}</h2>
								<hr />
								<label>
									<strong>Product Price:</strong>
								</label>
								<p className='price'>CD$ {product.price}</p>
								<hr />
								<label>
									<strong>Product description</strong>
								</label>
								<p className='description'>{product.description}</p>

								<Row>
									<Col md='6' sm='6'></Col>
									<Col md='6' sm='6'></Col>
								</Row>
								<hr />
								<Row>
									<Col md='5' sm='4'>
										<Button
											block
											className='btn-round'
											color='danger'
											href='/cart'>
											Go Cart <i className='fa fa-chevron-right' />
										</Button>
									</Col>
									<Col md='7' sm='8'>
										<Button
											block
											className='btn-round'
											color='danger'
											onClick={handleAddToCart}>
											Add to Cart{' '}
											{productQuantity > 0 && (
												<Button
													className='btn-just-icon mr-1'
													color='default'
													type='button'
													size='sm'>
													{' '}
													{productQuantity}
												</Button>
											)}
											<i className='fa fa-chevron-right' />
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</>
	);
}

export default ProductPage;
