import React, { useState, useEffect } from 'react';

// reactstrap components
function ProductPageHeader(props) {
	const [HeaderImage, setHeaderImage] = useState();
	document.documentElement.classList.remove('nav-open');
	React.useEffect(() => {
		document.body.classList.add('product-page');
		return function cleanup() {
			document.body.classList.remove('product-page');
		};
	});
	useEffect(() => {
		if (props.image) {
			setHeaderImage(props.image);
		}
	}, [props.image]);
	console.log(props.image);
	return (
		<>
			<div
				className='page-header page-header-xs'
				style={{
					backgroundImage: HeaderImage ? `url(${HeaderImage})` : 'none',
				}}>
				<div className='filter' />
			</div>
		</>
	);
}

export default ProductPageHeader;
