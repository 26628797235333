import React, { useEffect, useState } from 'react';
import {
	addBuilding,
	removeBuilding,
	fetchAllBuildings,
	fetchMyBusinessBuildings,
} from '../../../store/actions/building.js';
import { useSelector, useDispatch } from 'react-redux';

import Slider from 'nouislider';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Collapse,
	Label,
	FormGroup,
	Input,
	Container,
	Row,
	Col,
	CardImgOverlay,
	CardImg,
} from 'reactstrap';
function BuildingSearch(rest) {
	const dispatch = useDispatch();
	const { id } = useSelector((state) => state.currentUser.user);
	const buildings = useSelector((state) => state.allBuildings);
	const businessBuildings = useSelector((state) => state.businessBuildings);

	const [expandedAddresses, setExpandedAddresses] = useState({});

	useEffect(() => {
		dispatch(fetchAllBuildings(id));
		dispatch(fetchMyBusinessBuildings(id));
	}, [dispatch, id]);

	const toggleAddressDisplay = (id) => {
		setExpandedAddresses((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};
	const [distance, setDistance] = useState(true);
	const [type, setType] = useState(true);
	const [countUser, setCountUser] = useState(true);
	const [city, setCity] = useState(false);
	useEffect(() => {
		document.documentElement.classList.remove('nav-open');

		// Ensuring the element is available and properly initialized
		const sliderElement = document.getElementById('sliderDouble');
		if (sliderElement && !sliderElement.classList.contains('noUi-target')) {
			Slider.create(sliderElement, {
				start: [20, 80],
				connect: [false, true, false],
				step: 1,
				range: { min: 0, max: 100 },
			});
		}

		document.body.classList.add('ecommerce-page');

		return () => {
			document.body.classList.remove('ecommerce-page');
		};
	}, []); // Empty dependency array ensures this runs only once after the initial render
	// counter to count how many business are attached to the business
	const canAddMoreBuildings = businessBuildings.length < 2;
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	return (
		<div className='section section-gray'>
			<Container>
				<h3 className='section-title'>Find and Select Buildings</h3>
				<p className='description'>
					You Can Effectively Market Your Business in Targeted Buildings
				</p>
				<Row>
					<Col md='3'>
						<Card className='card-refine'>
							<div
								aria-expanded={true}
								aria-multiselectable={true}
								className='panel-group'
								id='accordion'>
								<CardHeader>
									<h3 className='mb-3 panel-title'>
										<strong>Your Business Buildings</strong>
									</h3>
									{businessBuildings.map((item) => {
										return (
											<>
												<div>
													<Card
														style={{
															height: '120px',
															background: 'black',
															overflow: 'hidden',
														}}
														inverse>
														<CardImg
															alt='...'
															className='img-rounded img-responsive'
															style={{
																height: '120px',
																objectFit: 'cover',
																opacity: '0.4',
															}}
															src={item.image[0]}
														/>
														<CardImgOverlay>
															<CardTitle style={{ color: 'white' }} tag='p'>
																<strong>{item.address}</strong>
															</CardTitle>
														</CardImgOverlay>
													</Card>
												</div>
											</>
										);
									})}
								</CardHeader>
								<CardHeader className='card-collapse' id='city' role='tab'>
									<h5 className='mb-0 panel-title'>
										<a
											aria-expanded={city}
											href='#pablo'
											onClick={(e) => {
												e.preventDefault();
												setCity(!city);
											}}>
											<strong>City</strong>{' '}
											<i className='nc-icon nc-minimal-down' />
										</a>
									</h5>
								</CardHeader>
								<Collapse isOpen={city}>
									<CardBody>
										<FormGroup check>
											<Label check>
												<Input defaultChecked defaultValue='' type='checkbox' />
												Calgary <span className='form-check-sign' />
											</Label>
										</FormGroup>
										<FormGroup check>
											<Label check>
												<Input defaultValue='' disabled type='checkbox' />
												Vancouver <span className='form-check-sign' />
											</Label>
										</FormGroup>
										<FormGroup check>
											<Label check>
												<Input defaultValue='' disabled type='checkbox' />
												Toronto <span className='form-check-sign' />
											</Label>
										</FormGroup>
										<FormGroup check>
											<Label check>
												<Input defaultValue='' disabled type='checkbox' />
												Montreal <span className='form-check-sign' />
											</Label>
										</FormGroup>
										<FormGroup check>
											<Label check>
												<Input defaultValue='' disabled type='checkbox' />
												New York <span className='form-check-sign' />
											</Label>
										</FormGroup>
									</CardBody>
								</Collapse>
								<CardHeader className='card-collapse' id='distance' role='tab'>
									<h5 className='mb-0 panel-title'>
										<a
											aria-expanded={distance}
											href='#pablo'
											onClick={(e) => {
												e.preventDefault();
												setDistance(!distance);
											}}>
											<strong>Distance</strong>{' '}
											<i className='nc-icon nc-minimal-down' />
										</a>
									</h5>
								</CardHeader>
								<Collapse isOpen={distance}>
									<CardBody>
										<div className='form-check-radio'>
											<Label check>
												<Input
													defaultValue='option1'
													id='distanceRadios1'
													name='distanceRadios'
													type='radio'
												/>
												All <span className='form-check-sign' />
											</Label>
										</div>
										<div className='form-check-radio'>
											<Label check>
												<Input
													defaultChecked
													defaultValue='option2'
													id='distanceRadios2'
													name='distanceRadios'
													type='radio'
												/>
												5 Miles <span className='form-check-sign' />
											</Label>
										</div>
										<div className='form-check-radio disabled'>
											<Label check>
												<Input
													defaultValue='option3'
													disabled
													id='distanceRadios3'
													name='distanceRadios'
													type='radio'
												/>
												25 Miles <span className='form-check-sign' />
											</Label>
										</div>
									</CardBody>
								</Collapse>
								<CardHeader className='card-collapse' id='type' role='tab'>
									<h5 className='mb-0 panel-title'>
										<a
											aria-expanded={type}
											href='#pablo'
											onClick={(e) => {
												e.preventDefault();
												setType(!type);
											}}>
											<strong>Building Type</strong>{' '}
											<i className='nc-icon nc-minimal-down' />
										</a>
									</h5>
								</CardHeader>
								<Collapse isOpen={type}>
									<CardBody>
										<FormGroup check>
											<Label check>
												<Input defaultChecked defaultValue='' type='checkbox' />
												All <span className='form-check-sign' />
											</Label>
										</FormGroup>
										<FormGroup check>
											<Label check>
												<Input defaultValue='' type='checkbox' />
												Rental <span className='form-check-sign' />
											</Label>
										</FormGroup>
										<FormGroup check>
											<Label check>
												<Input defaultValue='' type='checkbox' />
												Condominium <span className='form-check-sign' />
											</Label>
										</FormGroup>
									</CardBody>
								</Collapse>
								<CardHeader className='card-collapse' id='countUser' role='tab'>
									<h5 className='mb-0 panel-title'>
										<a
											aria-expanded={countUser}
											href='#pablo'
											onClick={(e) => {
												e.preventDefault();
												setCountUser(!countUser);
											}}>
											<strong>User per building</strong>
											<i className='nc-icon nc-minimal-down' />
										</a>
									</h5>
								</CardHeader>
								<Collapse isOpen={countUser}>
									<CardBody>
										<div className='form-check-radio'>
											<Label check>
												<Input
													defaultValue='option1'
													disabled
													id='exampleRadios1'
													name='exampleRadios'
													type='radio'
												/>
												20 to 40 <span className='form-check-sign' />
											</Label>
										</div>
										<div className='form-check-radio'>
											<Label check>
												<Input
													defaultValue='option2'
													disabled
													id='exampleRadios2'
													name='exampleRadios'
													type='radio'
												/>
												41 to 60 <span className='form-check-sign' />
											</Label>
										</div>
										<div className='form-check-radio'>
											<Label check>
												<Input
													defaultChecked
													defaultValue='option3'
													id='exampleRadios3'
													name='exampleRadios'
													type='radio'
												/>
												60 + <span className='form-check-sign' />
											</Label>
										</div>
									</CardBody>
								</Collapse>
								<hr />
							</div>
						</Card>
						{/* end card */}
					</Col>
					<Col md='9'>
						<div className='products'>
							{!canAddMoreBuildings && (
								<div className='alert alert-info'>
									You are limited to only two buildings. If you want to add
									more, please remove buildings.
								</div>
							)}
							{rest.errors.message && (
								<div className='alert alert-danger'>{rest.errors.message}</div>
							)}

							<Row>
								{buildings.map((item) => {
									const isExpanded = expandedAddresses[item._id];
									const displayAddress =
										isExpanded || item.address.length <= 20
											? item.address
											: `${item.address.substring(0, 20)}...`;

									return (
										<Col md='4' sm='4' key={item._id}>
											<Card className='card-product card-plain'>
												<div className='card-image'>
													<a href='#pablo' onClick={(e) => e.preventDefault()}>
														<img
															alt='...'
															className='img-rounded img-responsive'
															style={{ height: '160px', width: '100%' }}
															src={item.image[0]}
														/>
													</a>
												</div>
												<CardBody>
													<div className='card-title'>
														<CardHeader tag='h5'>
															{displayAddress}
															{item.address.length > 20 && (
																<a
																	href='#'
																	onClick={(e) => {
																		e.preventDefault();
																		toggleAddressDisplay(item._id);
																	}}
																	style={{
																		marginLeft: '5px',
																		fontSize: 'smaller',
																	}}>
																	{isExpanded ? 'less' : 'more'}
																</a>
															)}
														</CardHeader>
													</div>
													<div className='card-title'>
														<CardFooter>
															{businessBuildings.find(
																(e) => e._id === item._id
															) ? (
																<>
																	{' '}
																	<Button
																		className='btn-round mr-1'
																		color='danger'
																		size='sm'
																		outline
																		type='button'
																		onClick={() => {
																			dispatch(removeBuilding(id, item._id));
																		}}>
																		REMOVE
																	</Button>
																</>
															) : (
																<>
																	<Button
																		onClick={() => {
																			dispatch(addBuilding(id, item._id));
																		}}
																		disabled={!canAddMoreBuildings}
																		className='btn-round mr-1'
																		size='sm'
																		color='success'
																		outline
																		type='button'>
																		ADD
																	</Button>
																</>
															)}
															<Button
																disabled
																size='sm'
																className='btn-round'
																color='default'
																outline
																type='button'>
																More Info
															</Button>
														</CardFooter>
													</div>
												</CardBody>
											</Card>
										</Col>
									);
								})}
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default BuildingSearch;
