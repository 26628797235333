import axios from 'axios';

// Action Types
import {
	SET_CART,
	SET_SESSION_ID,
	SET_CART_IS_REQUESTED,
} from '../actionTypes';
import { addError } from './errors';
import { addSuccess } from './success';
// Action Creators

// This action sets the entire cart, including sessionId and cartItems
export const setCart = (cartData) => ({
	type: SET_CART,
	payload: cartData, // Expecting { sessionId, items } structure from backend
});
// action to load the cart
export const loadShoppingCart = () => {
	return async (dispatch, getState) => {
		try {
			// Retrieve the sessionId from Redux state or local storage
			let sessionId =
				getState().shoppingCart.sessionId || localStorage.getItem('sessionId');

			if (!sessionId) {
				throw new Error('Session ID is missing');
			}

			// Make the request to load the cart using the sessionId
			const response = await axios.get(`/api/purchase/cart/load`, {
				params: { sessionId },
			});

			if (response.data) {
				const { sessionId, products, cartIsRequested } = response.data;

				// Dispatch the setCart action to update the cart items
				dispatch(
					setCart({
						sessionId,
						items: products,
						cartIsRequested: cartIsRequested,
					})
				);
			}
		} catch (err) {
			// Dispatch an error action if something goes wrong
			dispatch(addError(err?.response?.data?.message || err.message));
		}
	};
};

// This action sets the sessionId in the store and local storage
export const setSessionId = (sessionId) => {
	// Save sessionId to local storage
	localStorage.setItem('sessionId', sessionId);

	return {
		type: SET_SESSION_ID,
		payload: sessionId,
	};
};

// This action adds an item to the cart
export const postCartItem = (productId) => async (dispatch, getState) => {
	try {
		// Check if sessionId exists in state or local storage
		let sessionId =
			getState().shoppingCart.sessionId || localStorage.getItem('sessionId');

		// If no sessionId, create one
		if (!sessionId) {
			let response = await axios.post('/api/purchase/session/create');
			sessionId = response.data.sessionId;
			dispatch(setSessionId(sessionId)); // Dispatch setSessionId to update the store
		}

		// Add item to the cart
		const response = await axios.post(`/api/purchase/cart/add/${productId}`, {
			sessionId,
			productId,
		});
		if (response.data) {
			const { sessionId, products, cartIsRequested } = response.data;

			// Dispatch the setCart action to update the cart items
			dispatch(
				setCart({
					sessionId,
					items: products,
					cartIsRequested: cartIsRequested,
				})
			);
			dispatch(addSuccess('Added to cart!!'));
		}
	} catch (error) {
		console.error('Error adding to cart:', error);
	}
};
//action to remove product from cart
export const removeCartItem = (productId) => {
	return async (dispatch, getState) => {
		try {
			let sessionId =
				getState().shoppingCart.sessionId || localStorage.getItem('sessionId');
			await axios.put(`/api/purchase/cart/remove/${productId}`, null, {
				params: { sessionId },
			});

			// Fetch the updated cart after item removal
			const response = await axios.get(`/api/purchase/cart/load`, {
				params: { sessionId },
			});
			if (response.data) {
				const { sessionId, products } = response.data;

				// Dispatch the setCart action to update the cart items
				dispatch(setCart({ sessionId, items: products }));
			}
		} catch (err) {
			console.error('Failed to remove item from cart:', err);
			dispatch(addError(err?.message));
		}
	};
};

// Action creator for setting the cartIsRequested in the state
export const setCartIsRequested = (cartIsRequested) => ({
	type: SET_CART_IS_REQUESTED,
	payload: cartIsRequested,
});

// Action to set the cartIsRequested to true in the cart
export const postCartRequest = (businessId) => async (dispatch, getState) => {
	try {
		// Retrieve the sessionId from Redux state or local storage
		const sessionId =
			getState().shoppingCart.sessionId || localStorage.getItem('sessionId');

		if (!sessionId) {
			dispatch(
				addError('Session ID is missing. Unable to complete the request.')
			);
			return;
		}

		// Send POST request to update the cartIsRequested status
		const response = await axios.post(`/api/purchase/cart/cartIsRequested`, {
			sessionId,
			businessId,
		});

		if (response.status === 200) {
			const { cartIsRequested, message } = response.data;

			// Dispatch actions to update state and notify user
			dispatch(setCartIsRequested(cartIsRequested));
			dispatch(addSuccess(message));
		}
	} catch (error) {
		console.error('Error updating the cart request status:', error);
		dispatch(
			addError(
				error.response?.data.message || 'Error completing the purchase request.'
			)
		);
	}
};
