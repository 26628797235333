import { apiCall, setTokenHeader } from '../../services/api';
import { SET_CURRENT_USER } from '../actionTypes';
import { addError, removeError } from './errors';
import { addSuccess } from './success';

//function that we dispatch to redux reducer
export function setCurrentUser(user) {
	return {
		type: SET_CURRENT_USER,
		user,
	};
}

export function setAuthorizationToken(token) {
	setTokenHeader(token);
}
export const logout = () => {
	return (dispatch) => {
		localStorage.clear();
		setAuthorizationToken(false);
		dispatch(setCurrentUser({}));
	};
};

//function we run to login or signup successfully its a promiss that will be completed after API call
export const authUserSignup = (formData) => async (dispatch) => {
	try {
		const { token, ...user } = await apiCall('post', '/api/auth/signup', {
			...formData,
		});

		localStorage.setItem('jwtToken', token);
		setAuthorizationToken(token);
		dispatch(setCurrentUser(user));
		dispatch(removeError());
		dispatch(addSuccess('Welcome to weciny!!'));
	} catch (err) {
		console.error('Error during sign up:', err);
		const errorMessage = err.message || JSON.stringify(err);
		dispatch(addError(errorMessage));
	}
};

// sign in action
export const authUserSignin = (formData) => async (dispatch) => {
	try {
		const { token, ...user } = await apiCall(
			'post',
			'/api/auth/signin',
			formData
		);

		localStorage.setItem('jwtToken', token);
		setAuthorizationToken(token);
		dispatch(setCurrentUser(user));
		dispatch(removeError());

		// Return a resolved promise indicating success
		return;
	} catch (err) {
		// console.error('Error during sign in:', err);
		const errorMessage = err.message || JSON.stringify(err);
		dispatch(addError(errorMessage));
	}
};

// action to remove users
export function userDeavtivate(userId) {
	return (dispatch) => {
		// wrap our thunk in a promise so we can wait for the API call
		return new Promise((resolve, reject) => {
			return apiCall('post', `/api/auth/deactivate/${userId}`)
				.then(() => {
					localStorage.clear();
					setAuthorizationToken(false);
					dispatch(setCurrentUser({}));
					resolve(); // indicate that the API call succeeded
				})
				.catch((err) => {
					dispatch(addError(err?.message));
					reject(); // indicate the API call failed
				});
		});
	};
}
