import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import {
	loadShoppingCart,
	postCartItem,
	removeCartItem,
	postCartRequest,
} from '../../store/actions/purchase';
import {
	Button,
	ButtonGroup,
	Table,
	Container,
	Row,
	Col,
	Alert,
} from 'reactstrap';

const Cart = (rest) => {
	const dispatch = useDispatch();
	const cart = useSelector((state) => state.shoppingCart);
	const businessId = cart.items.items[0].productId.business;
	useEffect(() => {
		dispatch(loadShoppingCart()).catch((err) => {
			console.error('Failed to fetch cart data:', err);
		});
	}, [dispatch]);

	const totalPrice = cart.items.items.reduce((total, item) => {
		return total + item.quantity * item.productId.price;
	}, 0);
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);

	const cartIsRequested = useSelector(
		(state) => state.shoppingCart.cartIsRequested
	);

	const handleCompleteClick = () => {
		console.log(cartIsRequested);
		dispatch(postCartRequest(businessId));
	};

	return (
		<>
			<Navbar />
			<div className='section section-gray'>
				<Container className='tim-container'>
					<Row>
						<Col md='12'>
							<h4 className='title'>Shopping Cart Table</h4>
						</Col>
						<Col className='ml-auto mr-auto' md='10'>
							<Table className='table-shopping' responsive>
								<thead>
									<tr>
										<th className='text-center' />
										<th />
										<th className='text-right'>Price</th>
										<th className='text-right'>Quantity</th>
										<th className='text-right'>Total</th>
									</tr>
								</thead>
								<tbody>
									{cart.items.items.length > 0 ? (
										cart.items.items.map((item) => {
											const itemPrice = item.quantity * item.productId.price;
											return (
												<tr key={item.productId._id}>
													<td>
														<a href={`/product-page/${item.productId._id}`}>
															<div className='img-container'>
																<img
																	alt='...'
																	src={item.productId?.image?.[0]}
																/>
															</div>
														</a>
													</td>
													<td className='td-product'>
														<strong>{item.productId.title}</strong>
														<p>{item.productId.description}</p>
													</td>
													<td className='td-price'>
														<small>CD$</small>
														{item.productId.price}
													</td>
													<td className='td-number td-quantity'>
														{item.quantity}
														<ButtonGroup>
															<Button
																className='btn-border btn-round'
																color='default'
																size='sm'
																onClick={() => {
																	if (item.productId && item.productId._id) {
																		dispatch(
																			removeCartItem(item.productId._id)
																		);
																	}
																}}>
																-
															</Button>
															<Button
																className='btn-border btn-round'
																color='default'
																size='sm'
																onClick={() => {
																	if (item.productId && item.productId._id) {
																		dispatch(postCartItem(item.productId._id));
																	}
																}}>
																+
															</Button>
														</ButtonGroup>
													</td>
													<td className='td-number'>
														<small>CD$</small>
														{itemPrice}
													</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td colSpan='5'>Your cart is empty.</td>
										</tr>
									)}
									<tr>
										<td colSpan='2' />
										<td />
										<td className='td-total'>Total</td>
										<td className='td-total'>
											<small>CD$</small>
											{totalPrice.toFixed(2)}
										</td>
									</tr>
									<tr className='tr-actions'>
										<td colSpan='3'>
											<p>
												The items are available for in-store pay and pick-up
												only.
											</p>
											{cartIsRequested && (
												<Alert>
													Your request has been successfully sent to the store!
												</Alert>
											)}
										</td>

										<td className='text-right' colSpan='2'>
											<Button
												color='danger'
												size='lg'
												type='button'
												onClick={handleCompleteClick}
												disabled={cartIsRequested}>
												Reserve Items <i className='fa fa-chevron-right' />
											</Button>
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</>
	);
};

export default Cart;
