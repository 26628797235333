import React from 'react';
import Navbar from '../Components/NavbarAuth';
import SectionSignUpForms from 'views/SignupPage/Sections/Forms';
import Footer from '../Components/Footer';

function RegisterPage(rest) {
	return (
		<>
			<Navbar {...rest} />
			<SectionSignUpForms {...rest} />
			<Footer />
		</>
	);
}

export default RegisterPage;
