import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';

// core components

function EcommerceHeader(props) {
	return (
		<>
			<div
				className='page-header page-header-xs settings-background'
				style={{
					backgroundImage: 'url(' + props.headerImage + ')',
				}}>
				<div className='filter' />
				<div className='content-center'>
					<Container className='text-center'>
						<h1>
							<strong>{props.businessName}</strong>
						</h1>
						{/* <h3>{props.subTitle}</h3> */}
					</Container>
				</div>
			</div>
		</>
	);
}

export default EcommerceHeader;
