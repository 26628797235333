import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { removeError } from '../store/actions/errors';
import { removeSuccess } from '../store/actions/success';

export default function withAuth(ComponentToBeRendered) {
	function Authenticate() {
		const navigate = useNavigate();
		const isAuthenticated = useSelector(
			(state) => state.currentUser.isAuthenticated
		);

		const errors = useSelector((state) => state.errors);
		const success = useSelector((state) => state.success);
		useEffect(() => {
			if (isAuthenticated === false) {
				navigate('/signin');
			}
		}, []);
		useEffect(() => {
			return () => {
				if (isAuthenticated === false) {
					navigate('/signin');
				}
			};
		}, []);
		return (
			<div>
				{!isAuthenticated ? (
					<Navigate to='/signin' />
				) : (
					<ComponentToBeRendered
						errors={errors}
						removeError={removeError}
						success={success}
						removeSuccess={removeSuccess}
					/>
				)}
			</div>
		);
	}

	return Authenticate;
}
