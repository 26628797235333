import React from 'react';
import withAuth from '../../hocs/withAuth';
import Navbar from '../Components/NavbarAuth';
import Footer from '../Components/Footer';
import SectionProduct from './Sections/SectionProduct';
import './index.css'; // Import custom styles for additional CSS

function ProductPage(rest) {
	console.log(rest);
	return (
		<div>
			<Navbar {...rest} />
			<SectionProduct {...rest} />
			<Footer />
		</div>
	);
}
export default withAuth(ProductPage);
