import React from 'react';
import withAuth from '../../hocs/withAuth';
// core components
import Navbar from 'views/Components/NavbarAuth.js';
import SectionBuildingSearch from './Sections/BuildingSearch';
import Footer from 'views/Components/Footer.js';

function BuildingSearchPage(rest) {
	// states for collapses

	return (
		<>
			<Navbar {...rest} />

			<div className='wrapper'>
				<SectionBuildingSearch {...rest} />
				<Footer />
			</div>
		</>
	);
}

export default withAuth(BuildingSearchPage);
