import React, { useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	Input,
	FormGroup,
	Col,
	Label,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { editProfile } from '../../../store/actions/business';

function AboutEditModal(props) {
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const dispatch = useDispatch();
	const { id } = useSelector((state) => state.currentUser.user);
	const [phone, setPhone] = React.useState(props.valuePhone);
	const [website, setWebsite] = React.useState(props.valueWebsite);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(editProfile(id, { phone, website }));
		setPhone();
		setWebsite('');
		setModal(false);
	};
	const { valuePhone, valueWebsite } = props;
	return (
		<div>
			<Button
				className='btn-border btn-round ml-2'
				color='default'
				outline
				type='button'
				onClick={toggle}>
				<i className='nc-icon nc-settings-gear-65 mr-1' />
				Edit
			</Button>
			<Modal isOpen={modal} toggle={toggle} {...props}>
				<ModalHeader toggle={toggle}>Add your contact information</ModalHeader>

				<ModalBody>
					<Form onSubmit={handleSubmit}>
						<FormGroup row>
							<Label for='exampleEmail' sm={2}>
								Phone
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									id='phone'
									name='phone'
									onChange={(e) => {
										setPhone(e.target.value);
									}}
									value={phone}
									placeholder={valuePhone}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for='exampleEmail' sm={2}>
								Website
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									id='website'
									name='website'
									onChange={(e) => {
										setWebsite(e.target.value);
									}}
									value={website}
									placeholder={valueWebsite}
								/>
							</Col>
						</FormGroup>
						<Button color='primary' type='submit'>
							Submit
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default AboutEditModal;
