import { apiCall } from '../../services/api';
import { addError } from './errors';
import { addSuccess } from './success';
import {
	LOAD_MYBUSINESS,
	REMOVE_NEWSFEED,
	POST_NEWSFEED,
	LOAD_BUSINESSES,
	LOAD_BUSINESS_IMAGE,
	LOAD_BUSINESS_LOGO_IMAGE,
} from '../actionTypes';

// =======================================================
// TO LOAD MYBUSINESS
export const loadMyBusiness = (myBusiness) => ({
	type: LOAD_MYBUSINESS,
	myBusiness,
});

export const fetchMyBusiness = (businessId) => async (dispatch) => {
	try {
		const res = await apiCall('get', `/api/business/${businessId}`);
		dispatch(loadMyBusiness(res));
	} catch (err) {
		dispatch(addError(err?.message));
	}
};
// EDIT Business Profile
export const editProfile = (businessId, fields) => {
	return async (dispatch) => {
		try {
			const res = await apiCall(
				'put',
				`/api/business/${businessId}/edit`,
				fields
			);
			dispatch(loadMyBusiness(res));
			dispatch(addSuccess('Successfully edited!!'));
		} catch (err) {
			dispatch(addError(err.message));
		}
	};
};

// action to edit the header image ===============================================
export const loadBusinessImage = (imageURL) => ({
	type: LOAD_BUSINESS_IMAGE,
	image: imageURL,
});

// Thunk action for editing header image
export const editHeaderImage = (businessId, formData) => {
	return async (dispatch) => {
		const fd = new FormData();
		fd.append('image', formData);
		try {
			const res = await apiCall('put', `/api/business/${businessId}/image`, fd);
			dispatch(loadBusinessImage(res));
			dispatch(addSuccess('Successfully edited!!'));
		} catch (err) {
			dispatch(addError(err?.message));
		}
	};
};
// action to edit the logo image ===============================================
export const loadBusinessLogoImage = (imageURL) => ({
	type: LOAD_BUSINESS_LOGO_IMAGE,
	image: imageURL,
});

// Thunk action for editing header image
export const editLogoImage = (businessId, formData) => {
	return async (dispatch) => {
		const fd = new FormData();
		fd.append('image', formData);
		try {
			const res = await apiCall(
				'put',
				`/api/business/${businessId}/image/logo`,
				fd
			);
			dispatch(loadBusinessLogoImage(res));
			dispatch(addSuccess('Successfully edited!!'));
		} catch (err) {
			dispatch(addError(err?.message));
		}
	};
};

// //CREATING A PRODUC ACTIOn
// export const addProduct = (newProduct) => ({
//   type: POST_PRODUCT,
//   newProduct,
// });
//Action to post a product
export const postProduct = (businessId, formData) => async (dispatch) => {
	try {
		const fd = new FormData();
		formData.files.forEach((file) => fd.append('files[]', file));
		fd.append('description', formData.description);
		fd.append('price', formData.price);
		fd.append('title', formData.title);

		const res = await apiCall('post', `/api/product/product/${businessId}`, fd);
		dispatch(loadMyBusiness(res));
		dispatch(addSuccess('Successfully Created!!'));
	} catch (err) {
		dispatch(addError(err?.message));
	}
};
//Action to post a contactus
export const postContact = (contact) => (dispatch) => {
	return apiCall('post', `/api/business/contact`, contact)
		.then((res) => {
			dispatch(addSuccess('We have received your Message!!'));
			return res;
		})
		.catch((err) => {
			dispatch(addError(err?.message));
		});
};

// Action to update the business newsfeeds in the Redux store
const updateBusinessNewsfeeds = (newsFeed) => ({
	type: POST_NEWSFEED,
	newsFeed,
});

// Action to create a news feed for a business
export const postNewsFeed = (businessId, formData) => async (dispatch) => {
	const fd = new FormData();
	formData.files.map((file) => fd.append('files[]', file));
	fd.append('title', formData.title);
	fd.append('description', formData.description);
	fd.append('subject', formData.subject);
	try {
		const res = await apiCall(
			'post',
			`/api/business/newsfeed/${businessId}`,
			fd
		);
		// Dispatch the action to update the newsfeeds in the Redux store
		dispatch(updateBusinessNewsfeeds(res));
		dispatch(addSuccess('Posted Successfully!!'));
	} catch (err) {
		// Handle errors by dispatching an error action
		dispatch(addError(err?.message));
	}
};
// Action to remove a news feed
const removeNewsFeedById = (id) => ({
	type: REMOVE_NEWSFEED,
	id,
});

export const removeNewsFeed = (newsfeedId) => {
	return async (dispatch, getState) => {
		const { currentUser } = getState();
		const businessId = currentUser.user.id;

		try {
			await apiCall(
				'delete',
				`/api/business/${businessId}/newsfeed/${newsfeedId}`
			);
			dispatch(removeNewsFeedById(newsfeedId));
			dispatch(addSuccess('Successfully Removed!!'));
		} catch (err) {
			dispatch(addError(err?.message));
		}
	};
};
//Action to fetch all businesses
export const loadBusinesses = (businesses) => ({
	type: LOAD_BUSINESSES,
	businesses,
});

export const fetchBusinesses = () => {
	return (dispatch) => {
		return apiCall('get', '/api/business')
			.then((res) => {
				dispatch(loadBusinesses(res));
			})
			.catch((err) => {
				dispatch(addError(err?.message));
			});
	};
};
