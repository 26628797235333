/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Row } from 'reactstrap';

// core components

function FooterBlack() {
	return (
		<>
			<footer className='footer footer-white'>
				<Container>
					<Row>
						<nav className='footer-nav'>
							<ul>
								<li>
									<a href='/about-us' className='mr-1'>
										About Us
									</a>
								</li>
								<li>
									<a href='/terms' className='mr-1'>
										Terms & Privacy
									</a>
								</li>
								<li>
									<a href='/contact-us' className='mr-1'>
										Contact Us
									</a>
								</li>
							</ul>
						</nav>
						<div className='credits ml-auto'>
							<strong>
								<span className='copyright'>
									© {new Date().getFullYear()}
									, made with <i className='fa fa-heart heart' />{' '}
									<a href='https://www.weciny.com' target='_blank'>
										Weciny
									</a>{' '}
								</span>
							</strong>
						</div>
					</Row>
				</Container>
			</footer>
		</>
	);
}

export default FooterBlack;
