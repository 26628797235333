import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
//STORE ACTIONS================================
import { loadProduct } from '../../../store/actions/product';
import { editProduct } from '../../../store/actions/product';
import { removeProduct } from '../../../store/actions/product';
// react plugin that creates an input with badges
import TagsInput from 'react-tagsinput';
// reactstrap components
import {
	Button,
	FormGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
	Card,
	Carousel,
	CarouselIndicators,
	CarouselItem,
} from 'reactstrap';

//MAIN FUNCTION========================
function ProductAdminPage(rest) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { productId } = useParams();
	const product = useSelector((state) => state.theProduct);
	const { id } = useSelector((state) => state.currentUser.user);
	// const errors = useSelector((state) => state.errors);
	const [tags, setTags] = useState([]);
	const [categories, setCategories] = useState([]);
	const [title, setTitle] = useState('');
	const [price, setPrice] = useState('');
	const [description, setDescription] = useState('');
	useEffect(() => {
		if (productId) {
			dispatch(loadProduct(productId));
		}
	}, [productId, dispatch]);

	useEffect(() => {
		setTags(product?.tags || []);
		setCategories(product?.categories || []);
		setTitle(product?.title || '');
		setPrice(product?.price || '');
		setDescription(product?.description || '');
	}, [product]);
	document.documentElement.classList.remove('nav-open');
	useEffect(() => {
		document.body.classList.add('add-product');

		return function cleanup() {
			document.body.classList.remove('add-product');
		};
	}, []);
	// carousel states and functions================================================
	const [activeIndex, setActiveIndex] = useState(0);
	const [animating, setAnimating] = useState(false);
	const productImages = product?.image || [];
	const onExiting = () => {
		setAnimating(true);
	};
	const onExited = () => {
		setAnimating(false);
	};
	const next = () => {
		if (animating) return;
		const nextIndex =
			activeIndex === productImages.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};
	const previous = () => {
		if (animating) return;
		const nextIndex =
			activeIndex === 0 ? productImages.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};
	const goToIndex = (newIndex) => {
		if (animating) return;
		setActiveIndex(newIndex);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			editProduct(product._id, id, {
				tags,
				categories,
				title,
				price,
				description,
			})
		)
			.then(() => {
				navigate(0); // Refresh the page after the dispatch completes
			})
			.catch((err) => {
				console.error('Error editing product:', err);
			});
	};
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	return (
		<>
			<div className='main'>
				<div className='section'>
					<Container>
						<div>
							{rest.errors.message && (
								<div className='alert alert-danger'>{rest.errors.message}</div>
							)}
							{rest.success.message && (
								<div className='alert alert-success'>
									{rest.success.message}
								</div>
							)}
							<Row>
								<Col md='6' sm='6'>
									<div className='title'>
										<h3>Product Images</h3>
									</div>
									<Card className='card-raised page-carousel'>
										<Carousel
											activeIndex={activeIndex}
											next={next}
											previous={previous}>
											<CarouselIndicators
												items={productImages}
												activeIndex={activeIndex}
												onClickHandler={goToIndex}
											/>
											{productImages.map((image, index) => {
												return (
													<CarouselItem
														onExiting={onExiting}
														onExited={onExited}
														key={index}>
														<img src={image} alt='...' />
													</CarouselItem>
												);
											})}
											<a
												className='left carousel-control carousel-control-prev'
												data-slide='prev'
												href='#pablo'
												onClick={(e) => {
													e.preventDefault();
													previous();
												}}
												role='button'>
												<span className='fa fa-angle-left' />
												<span className='sr-only'>Previous</span>
											</a>
											<a
												className='right carousel-control carousel-control-next'
												data-slide='next'
												href='#pablo'
												onClick={(e) => {
													e.preventDefault();
													next();
												}}
												role='button'>
												<span className='fa fa-angle-right' />
												<span className='sr-only'>Next</span>
											</a>
										</Carousel>
									</Card>
									<h6>Tags</h6>
									<div id='tags'>
										<TagsInput
											onlyUnique
											onChange={(value) => setTags(value)}
											tagProps={{
												className: 'react-tagsinput-tag badge-success',
											}}
											value={tags}
											defaultValue={product.tags}
										/>
									</div>
									<h6>Categories</h6>
									<div id='tags-2'>
										<TagsInput
											onlyUnique
											onChange={(value) => setCategories(value)}
											tagProps={{
												className: 'react-tagsinput-tag badge-success',
											}}
											value={categories}
											defaultValue={product.categories}
										/>
									</div>
								</Col>
								<Col md='6' sm='6'>
									<FormGroup>
										<h6>
											Name <span className='icon-danger'>*</span>
										</h6>
										<Input
											type='text'
											id='title'
											name='title'
											className='border-input'
											onChange={(e) => {
												setTitle(e.target.value);
											}}
											value={title}
											defaultValue={product.title}
										/>
									</FormGroup>
									<Row className='price-row'>
										<Col md='6'>
											<h6>
												Price <span className='icon-danger'>*</span>
											</h6>
											<InputGroup className='border-input'>
												<Input
													className='border-input'
													type='number'
													id='price'
													name='price'
													defaultValue={product.price}
													onChange={(e) => {
														setPrice(e.target.value);
													}}
													value={price}
												/>
												<InputGroupAddon addonType='append'>
													<InputGroupText>
														<i className='fa fa-euro' />
													</InputGroupText>
												</InputGroupAddon>
											</InputGroup>
										</Col>
									</Row>
									<FormGroup>
										<h6>Description</h6>
										<Input
											className='textarea-limited'
											maxLength='150'
											rows='13'
											type='textarea'
											id='description'
											name='description'
											defaultValue={product.description}
											onChange={(e) => {
												setDescription(e.target.value);
											}}
											value={description}
										/>
										<h5>
											<small>
												<span
													className='pull-right'
													id='textarea-limited-message'>
													150 characters left
												</span>
											</small>
										</h5>
									</FormGroup>
								</Col>
							</Row>
							<Row className='buttons-row'>
								<Col md='4' sm='4'>
									<Button
										block
										className='btn-round'
										color='secondary'
										// outline
										type='reset'
										onChange={() => {
											setTags(product.tags);
											setCategories(product.categories);
											setTitle(product.title);
											setPrice(product.price);
											setDescription(product.description);
										}}>
										Cancel
									</Button>
								</Col>
								<Col md='4' sm='4'>
									{id === product.business._id && (
										<Button
											className='btn-round'
											onClick={() => {
												dispatch(removeProduct(product._id));
												navigate('/business-page');
											}}
											round
											color='danger'>
											Remove &nbsp;
										</Button>
									)}
								</Col>
								<Col md='4' sm='4'>
									<Button
										block
										className='btn-round'
										onClick={handleSubmit}
										color='primary'
										type='submit'>
										Save &amp; Publish
									</Button>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</div>
		</>
	);
}

export default ProductAdminPage;
