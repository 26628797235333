import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import { logout } from '../../store/actions/auth';
import { useSelector, useDispatch } from 'react-redux';
// nodejs library that concatenates strings
import classnames from 'classnames';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
	Collapse,
	NavbarBrand,
	Navbar,
	Nav,
	NavItem,
	NavLink,
	Button,
	Container,
} from 'reactstrap';

// core components

function NavbarMain() {
	// const [topbarIsOpen, setTopbarOpen] = useState(true);
	// const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
	const [publicPageLink, setPublicPageLink] = useState('');
	//redux store
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.currentUser.user);
	//finding the current location
	// const pageBusinessPublic = useMatch('/business-page/:businessId');
	const pageSignup = useMatch('/signup');
	const pageSignin = useMatch('/signin');
	const pagePricing = useMatch('/pricing');
	const pageBuildingSearch = useMatch('/building-search-page');
	//Styling
	const [navbarColor, setNavbarColor] = useState('navbar-light');
	const [bodyClick, setBodyClick] = useState(false);
	const [collapseOpen, setCollapseOpen] = useState(false);
	useEffect(() => {
		let headroom = new Headroom(document.getElementById('navbar-main'));
		// initialise
		headroom.init();

		const updateNavbarColor = () => {
			if (
				document.documentElement.scrollTop > 499 ||
				document.body.scrollTop > 499
			) {
				setNavbarColor('');
			} else if (
				document.documentElement.scrollTop < 500 ||
				document.body.scrollTop < 500
			) {
				setNavbarColor('');
			}
		};
		window.addEventListener('scroll', updateNavbarColor);
		return function cleanup() {
			window.removeEventListener('scroll', updateNavbarColor);
		};
	});
	useEffect(() => {
		setPublicPageLink(`/business-page/${currentUser.id}` || '/');
	}, [currentUser]);
	return (
		<>
			{currentUser.id ? (
				<div>
					{bodyClick ? (
						<div
							id='bodyClick'
							onClick={() => {
								document.documentElement.classList.toggle('nav-open');
								setBodyClick(false);
								setCollapseOpen(false);
							}}
						/>
					) : null}
					<Navbar
						className={classnames('fixed-top', navbarColor)}
						color='dark'
						id='navbar-main'
						expand='lg'>
						<Container>
							<div className='navbar-translate'>
								<NavbarBrand id='navbar-brand' to='/index' tag={Link}>
									{currentUser.businessName}
								</NavbarBrand>

								<button
									className='navbar-toggler'
									id='navigation'
									type='button'
									onClick={() => {
										document.documentElement.classList.toggle('nav-open');
										setBodyClick(true);
										setCollapseOpen(true);
									}}>
									<span className='navbar-toggler-bar bar1'></span>
									<span className='navbar-toggler-bar bar2'></span>
									<span className='navbar-toggler-bar bar3'></span>
								</button>
							</div>
							<Collapse navbar isOpen={collapseOpen}>
								<Nav className='ml-auto' navbar>
									{pageBuildingSearch === null && (
										<NavItem>
											<Button
												className='btn-border btn-round mr-1'
												color='success'
												size='md'
												href='/building-search-page'
												type='button'>
												Market with locals
											</Button>
										</NavItem>
									)}

									<NavItem>
										<Button
											className='btn-border btn-round mr-1'
											color='success'
											size='md'
											target='_blank'
											href={publicPageLink}
											type='button'>
											Your Public E-commerce
										</Button>
									</NavItem>

									<NavItem></NavItem>
									<Button
										className='btn-border btn-round mr-1'
										color='warning'
										onClick={() => dispatch(logout())}
										round>
										Sign out
									</Button>
								</Nav>
							</Collapse>
						</Container>
					</Navbar>
				</div>
			) : (
				<div>
					{bodyClick ? (
						<div
							id='bodyClick'
							onClick={() => {
								document.documentElement.classList.toggle('nav-open');
								setBodyClick(false);
								setCollapseOpen(false);
							}}
						/>
					) : null}
					<Navbar
						className={classnames('fixed-top', navbarColor)}
						id='navbar-main'
						expand='lg'>
						<Container>
							<div className='navbar-translate'>
								<NavbarBrand id='navbar-brand' to='/index' tag={Link}>
									Weciny
								</NavbarBrand>

								<button
									className='navbar-toggler'
									id='navigation'
									type='button'
									onClick={() => {
										document.documentElement.classList.toggle('nav-open');
										setBodyClick(true);
										setCollapseOpen(true);
									}}>
									<span className='navbar-toggler-bar bar1'></span>
									<span className='navbar-toggler-bar bar2'></span>
									<span className='navbar-toggler-bar bar3'></span>
								</button>
							</div>
							<Collapse navbar isOpen={collapseOpen}>
								<Nav className='ml-auto' navbar>
									{' '}
									{pageSignup === null && (
										<NavItem>
											<Button
												className='btn-round'
												color='default'
												href='/signup'>
												Create your online store
											</Button>
										</NavItem>
									)}
									{pagePricing === null && (
										<NavItem>
											<NavLink href='/pricing'>Pricing</NavLink>
										</NavItem>
									)}
									{pageSignin === null && (
										<NavItem>
											<NavLink href='/signin'>Sign in</NavLink>
										</NavItem>
									)}
								</Nav>
							</Collapse>
						</Container>
					</Navbar>
				</div>
			)}
		</>
	);
}

export default NavbarMain;
