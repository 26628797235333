import { apiCall } from '../../services/api';
import { addError } from './errors';
import { addSuccess } from './success';
import { LOAD_PRODUCT } from '../actionTypes';

// TO GET A PRODUCT
export const loadTheProduct = (product) => ({
	type: LOAD_PRODUCT,
	product,
});

export const loadProduct = (productId) => async (dispatch, getState) => {
	const { currentUser } = getState(); // Destructure directly
	const businessId = currentUser.user.id;

	try {
		const res = await apiCall('get', `/api/product/${businessId}/${productId}`);
		dispatch(loadTheProduct(res));
	} catch (err) {
		dispatch(addError(err.message));
	}
};

//ACTION TO REMOVE PRODUCT
export const removeProduct = (productId) => async (dispatch, getState) => {
	const { currentUser } = getState(); // Destructure to access currentUser directly
	const businessId = currentUser.user.id;

	try {
		await apiCall('delete', `/api/product/${businessId}/${productId}`);
		// dispatch(removeP(productId));
		dispatch(addSuccess('Product successfully removed!'));
	} catch (err) {
		dispatch(addError(err?.message || 'Failed to remove product'));
	}
};

//ACTION TO EDIT THE PRODUCT CONTENTS
export const editProduct = (productId, businessId, data) => {
	return async (dispatch) => {
		try {
			const res = await apiCall(
				'put',
				`/api/product/${productId}/${businessId}/edit`,
				data
			);
			dispatch(loadTheProduct(res)); // Load updated product details
			dispatch(addSuccess('Successfully edited!')); // Show success message
		} catch (err) {
			dispatch(addError(err.message)); // Dispatch error message
		}
	};
};
