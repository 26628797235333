import React, { useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	Input,
	FormGroup,
	Col,
	Label,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { editProfile } from '../../../store/actions/business';

function AboutEditModal(props) {
	const dispatch = useDispatch();
	const { id } = useSelector((state) => state.currentUser.user);
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [facebook, setFacebook] = React.useState(props.valueFacebook);
	const [instagram, setInstagram] = React.useState(props.valueInstagram);
	const [twitter, setTwitter] = React.useState(props.valueTwitter);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(editProfile(id, { twitter, instagram, facebook }));
		setFacebook('');
		setInstagram('');
		setTwitter('');
		setModal(false);
	};
	const { valueTwitter, valueInstagram, valueFacebook } = props;
	return (
		<div>
			<Button
				className='btn-border btn-round ml-2 mt-4'
				color='default'
				outline
				type='button'
				onClick={toggle}>
				<i className='nc-icon nc-settings-gear-65 mr-1' />
				Edit
			</Button>
			<Modal isOpen={modal} toggle={toggle} {...props}>
				<ModalHeader toggle={toggle}>
					Tell your customers about your business
				</ModalHeader>

				<ModalBody>
					<Form onSubmit={handleSubmit}>
						<FormGroup row>
							<Label for='exampleEmail' sm={2}>
								X
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									id='twitter'
									name='twitter'
									onChange={(e) => {
										setTwitter(e.target.value);
									}}
									value={twitter}
									placeholder={valueTwitter}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for='exampleEmail' sm={2}>
								Instagram
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									id='instagram'
									name='instagram'
									onChange={(e) => {
										setInstagram(e.target.value);
									}}
									value={instagram}
									placeholder={valueInstagram}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label for='exampleEmail' sm={2}>
								Facebook
							</Label>
							<Col sm={10}>
								<Input
									type='text'
									id='facebook'
									name='facebook'
									onChange={(e) => {
										setFacebook(e.target.value);
									}}
									value={facebook}
									placeholder={valueFacebook}
								/>
							</Col>
						</FormGroup>
						<Button color='primary' type='submit'>
							Submit
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default AboutEditModal;
