import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components

function SectionComponents() {
	return (
		<>
			<div className='section section-components'>
				<Row>
					<Col lg='6' md='12'>
						<Container>
							<div className='image-container'>
								<img
									alt='...'
									className='components-macbook'
									src={require('../Images/laptop2.png')}
								/>
								<img
									alt='...'
									className='table-img'
									src={require('../Images/about1.jpg')}
								/>
								<img
									alt='...'
									className='share-btn-img'
									src={require('../Images/card2.jpg')}
								/>
								<img
									alt='...'
									className='coloured-card-btn-img'
									src={require('../Images/10.jpg')}
								/>
								<img
									alt='...'
									className='coloured-card-img'
									src={require('../Images/9.jpg')}
								/>

								<img
									alt='...'
									className='pin-btn-img'
									src={require('../Images/microsoft.jpg')}
								/>
							</div>
						</Container>
					</Col>
					<Col className='ml-auto mr-auto' lg='4' md='10' sm='12'>
						<Container className='basic-container'>
							<h3 className='title'>
								<strong>An eCommerce platform</strong>
							</h3>
							<h6 className='category'>
								An online store that does everything for you
							</h6>
							<h5 className='description'>
								<strong>
									Create an online store in under a minute! Add your products
									with pictures, prices, and descriptions. Share information
									about yourself, your team, and your business goals. We&apos;ll
									ensure your customers see it all.
								</strong>
							</h5>
						</Container>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default SectionComponents;
