/*eslint-disable*/
import React from 'react';
import Navbar from '../Components/Navbar';
import image from './404.jpg';

export default function ErrorPage({ ...rest }) {
	const classes = useStyles();
	return (
		<div>
			<Navbar />
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: 'url(' + image + ')',
					backgroundSize: 'cover',
					backgroundPosition: 'top center',
				}}>
				<div className='content-center'>
					<h1 className='title'>404</h1>
					<h2 className='subtitle'>Page not found :(</h2>
					<h4 className='description'>Ooops! Looks like you got lost.</h4>
					<h4 className='description'>
						<a href='/' color='pinterest'>
							Get back to home page
						</a>
					</h4>
				</div>
			</div>
		</div>
	);
}
