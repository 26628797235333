import React from 'react';
import {
	Card,
	CardBody,
	CardFooter,
	CardTitle,
	Container,
	Row,
	Col,
} from 'reactstrap';
const LatestOffers = (props) => {
	const { business } = props;
	return (
		<div className='section section-blog'>
			<Container>
				<h3 className='section-blog' style={{ margin: '5%' }}>
					Blog Posts
				</h3>
				<Row>
					{business.newsFeeds.map((feed) => (
						<Col md='4' key={feed._id}>
							<Card className='card-blog'>
								<div className='card-image'>
									<a href='#pablo' onClick={(e) => e.preventDefault()}>
										<img
											alt='...'
											className='img img-raised'
											src={feed.image[0]}
										/>
									</a>
								</div>
								<CardBody>
									<CardTitle tag='h5'>
										<a href='#pablo' onClick={(e) => e.preventDefault()}>
											{feed.title}
										</a>
									</CardTitle>
									<p className='card-description'>
										{feed.description} <br />
									</p>
									<hr />
									<CardFooter>
										{/* <div className='stats'>
											<i className='fa fa-clock-o mr-1' />5 min read
										</div> */}
									</CardFooter>
								</CardBody>
							</Card>
						</Col>
					))}
				</Row>
			</Container>
		</div>
	);
};

export default LatestOffers;
