import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
import Navbar from '../Components/Navbar';
import Header from './Sections/Header';
import Footer from '../Components/Footer';

function BlogPost() {
	document.documentElement.classList.remove('nav-open');
	React.useEffect(() => {
		document.body.classList.add('blog-post');
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		return function cleanup() {
			document.body.classList.remove('blog-post');
		};
	});
	return (
		<>
			<Navbar />
			<Header />
			<div className='terms-section2'>
				<div className='wrapper'>
					<div className='main'>
						<div className='section section-white'>
							<Container>
								<Row>
									<Col className='ml-auto mr-auto text-center title' md='6'>
										<h2>Our Mission</h2>
										<h2> </h2>
										<h3 className='title-uppercase'>
											<small>
												{' '}
												Small businesses and local service providers bring
												unique character and vitality to their communities. They
												embody the spirit of neighbors helping neighbors and
												friends supporting friends. Beyond this, they contribute
												significantly to their local economies and are the
												backbone of their communities.
											</small>
										</h3>
										<h2> </h2>
										<h3 className='title-uppercase'>
											<small>
												{' '}
												In the past few years, many small business owners have
												faced significant challenges while striving to enhance
												their towns and serve their neighbors. In response, we
												created this platform to help them connect with their
												community.
											</small>
										</h3>
										<h2> </h2>
										<h3 className='title-uppercase'>
											<small>
												{' '}
												When you choose to buy locally, you contribute to a
												stronger, closer, and happier community. Your support
												brings more smiles and positivity to your surroundings.
											</small>
										</h3>
										<h2> </h2>
										<h3 className='title-uppercase'>
											<small> So! Shop local and help spread the word!</small>
										</h3>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default BlogPost;
