import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// reactstrap components
import { Container } from 'reactstrap';

// core components
import Navbar from '../Components/Navbar';
import Header from './Sections/Header';
import Footer from '../Components/Footer';

function BlogPost(rest) {
	const dispatch = useDispatch();
	document.documentElement.classList.remove('nav-open');
	React.useEffect(() => {
		document.body.classList.add('blog-post');
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		return function cleanup() {
			document.body.classList.remove('blog-post');
		};
	});
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	return (
		<>
			<Navbar />
			<Header />
			<div className='terms-section2'>
				<div className='wrapper'>
					<div className='main'>
						<div className='section section-white'>
							<Container>
								<h1>Terms of Service</h1>

								<h2>{'  '}</h2>
								<p>
									<i>Last updated on: May, 2024</i>
								</p>
								<h5>
									By signing up for a MyLocals account, you agree to be bound by
									the following terms and conditions. Please note that MyLocals
									is a product of Weciny Inc. By using MyLocals, you are
									entering into a contract with Weciny Inc.
								</h5>
								<div className='article-content'>
									<h2>1. Term Of Use</h2>
									<h4>
										<strong>➤ Who can use MyLocals</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										You may use MyLocals only if you can form a binding contract
										with Weciny Inc. and comply with these Terms and all
										applicable laws. When creating your online store, you must
										provide accurate and complete information. Use by
										individuals under the age of 13 is prohibited. If you open
										an account on behalf of an entity, you represent that you
										have the authority to bind the entity to these Terms.
										MyLocals may be software downloaded to your devices. We may
										automatically upgrade these products, and these Terms will
										apply to such upgrades.
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ Our license to you</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										Subject to these Terms and our policies we grant you a
										limited, non-exclusive, non-transferable, and revocable
										license to use Weciny business platform (MyLocals).
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ Fees, Payments, Taxes</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										୦ Free Setup: Setting up your online store on MyLocals is
										completely free. You can create and configure your store
										without any initial costs.{' '}
									</h5>
									<h5>
										୦ Subscription Fees: Charges will apply once you begin using
										features to enhance your profile and market your business.
										You will be billed according to the subscription plan you
										choose.
									</h5>{' '}
									<h5>
										୦ Increases. MyLocals reserves the right to increase its
										fees upon at least 90 days advance notice (e-mail or
										otherwise) to the Customer; provided, however, that fee
										increases will not take effect until the start of the next
										Subscription Term.{' '}
									</h5>
									<h5>
										୦ Tax Calculation: Taxes will be calculated based on your
										business location. The applicable tax amount will be added
										to your invoice.
									</h5>{' '}
									<h5>
										୦ Late Payments. Customer shall pay interest on all late
										payments with 15 days written prior notice, at the lesser of
										(a) 1.5% per month or (b) the highest rate permissible under
										applicable law, calculated daily and compounded monthly.
										Customer shall reimburse MyLocals for all costs and
										expenses, including attorneys’ fees, incurred in collecting
										any unpaid amounts owed by Customer hereunder.
									</h5>
									<h2>{'  '}</h2>
									<h2>2. Your Content</h2>
									<h4>
										<strong>➤ Posting content</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										MyLocals allows you to post your products, including photos,
										descriptions, blog posts, and other materials. Anything that
										you post or otherwise make available on our Products page is
										referred to as &quot;User Content.&quot; **You retain all
										rights in and are solely responsible for, the User Content
										you post to MyLocals.** By using MyLocals, you represent and
										warrant that you are the owner of, and/or have the right to
										use the product(s) that you display on MyLocals. For images
										of your products, this applies to the access of those
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ How MyLocals will use your content</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										By using MyLocals, you grant MyLocals a non-exclusive,
										royalty-free, transferable, sublicensable, worldwide license
										to store, display, save, create derivative works (i.e.
										marketing images) and distribute your User Content on
										MyLocals solely for the purposes of operating, developing,
										providing, and using MyLocals. We reserve the right to
										remove User Content for any reason, including User Content
										that we believe violates these Terms or our policies.
										**MyLocals does not claim ownership rights in any of the
										digital images that you access, use, create or modify using
										MyLocals (the “Materials”).** For the sole purpose of
										allowing MyLocals to display your product, including
										enabling us to display your photos and media through the
										MyLocals (for example, in Product Listings), you grant to
										MyLocals a non-exclusive, royalty-free license to use, copy,
										distribute and display those Materials (the “Materials
										License”), and the right to use your name in connection with
										the Materials. MyLocals use, copying, distribution and/or
										display of any Materials in connection with such Materials
										License shall be subject to MyLocals privacy policy,
										available at
										[mylocals.shop](https://www.mylocals.shop/terms) in the
										footer. For the avoidance of doubt, MyLocals will not,
										without your prior consent, use, display or modify any of
										your Materials other than in accordance with any activity
										initiated by you. Materials may be used by MyLocals for the
										purpose of advertising or promoting our Product to potential
										members. You may not use MyLocals in any manner that invades
										or infringes the privacy of any other person. You represent
										and warrant to MyLocals that Local Shop’s use of the
										Materials, as contemplated herein, does not and will not
										violate or infringe the rights (including copyright, moral
										rights, and other intellectual property rights and privacy
										and personality rights) of any other person or any
										applicable laws; and you have all the rights necessary to
										grant all permissions, rights and licenses set forth above.
										You also agree not to use MyLocals in any manner that could
										damage, disable, overburden, or impair MyLocals or interfere
										with any other party&apos;s use and enjoyment of MyLocals.
										You also agree not to attempt to gain unauthorized access to
										any other MyLocals member accounts, computer systems or
										networks associated with MyLocals. You also agree not to
										obtain or attempt to obtain any materials or information
										through any means not intentionally made available or
										provided for through MyLocals.
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ Feedback you provide</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										We value hearing from our users and are always interested in
										learning about ways we can make MyLocals better. If you
										choose to submit comments, ideas or feedback, you agree that
										we are free to use them without any restriction or
										compensation to you. By accepting your submission, MyLocals
										does not waive any rights to use similar or related feedback
										previously known to MyLocals, or developed by its employees,
										or obtained from sources other than you.
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ Copyright Policy</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										MyLocals has adopted and implemented the MyLocals Copyright
										Policy in accordance with the Digital Millennium Copyright
										Act and other applicable copyright laws. For more
										information, please contact us through our contact form
										located on mylocals.shops
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ Indemnity</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										If you use our Products for commercial purposes without
										agreeing to our Terms as determined in our sole and absolute
										discretion, you agree to indemnify and hold harmless
										MyLocals and its respective officers, directors, employees,
										and agents, from and against any claims, suits, proceedings,
										disputes, demands, liabilities, damages, losses, costs and
										expenses, including, without limitation, reasonable legal
										and accounting fees (including costs of defense of claims,
										suits or proceedings brought by third parties), in any way
										related to (a) your access to or use of our Products, (b)
										your User Content, or (c) your breach of any of these Terms.
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ Disclaimers</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										The Products and all included content are provided on an
										&quot;as is&quot; basis without warranty of any kind,
										whether express or implied. MyLocals SPECIFICALLY DISCLAIMS
										ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
										FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND
										ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF
										TRADE. MyLocals takes no responsibility and assumes no
										liability for any User Content that you or any other user or
										third-party posts or transmits using our Products. You
										understand and agree that you may be exposed to User Content
										that is inaccurate, objectionable, inappropriate for
										children, or otherwise unsuited to your purpose.
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ Limitation of Liability</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										TO THE MAXIMUM EXTENT PERMITTED BY LAW, MyLocals SHALL NOT
										BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
										CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
										REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY
										LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES,
										RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR INABILITY TO
										ACCESS OR USE THE PRODUCTS; (B) ANY CONDUCT OR CONTENT OF
										ANY THIRD PARTY ON THE PRODUCTS, INCLUDING WITHOUT
										LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF
										OTHER USERS OR THIRD PARTIES; OR (C) UNAUTHORIZED ACCESS,
										USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO
										EVENT SHALL MyLocals AGGREGATE LIABILITY FOR ALL CLAIMS
										RELATING TO THE PRODUCTS EXCEED ONE HUNDRED CANADIAN DOLLARS
										(CDN $100.00).
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ Governing law and jurisdiction</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										These Terms shall be governed by the laws of the Province of
										Ontario, without respect to its conflict of laws principles.
										We each agree to submit to the personal jurisdiction of a
										provincial court located in Canada for any actions not
										subject to Section 10 (Arbitration).
									</h5>
									<h2>{'  '}</h2>
									<h4>
										<strong>➤ General Terms</strong>
									</h4>
									<h2>{'  '}</h2>
									<h5>
										<strong>
											Notification procedures and changes to these Terms
										</strong>{' '}
										MyLocals reserves the right to determine the form and means
										of providing notifications to you, and you agree to receive
										legal notices electronically if we so choose. We may revise
										these Terms from time to time and the most current version
										will always be posted on our website. If a revision, at our
										sole discretion, is material we will notify you. By
										continuing to access or use the Products after revisions
										become effective, you agree to be bound by the revised
										Terms. If you do not agree to the new terms, please stop
										using MyLocals Product and Services.
									</h5>
									<h2>{'  '}</h2>
									<h5>
										<strong>Assignment</strong> These Terms, and any rights and
										licenses granted hereunder, may not be transferred or
										assigned by you, but may be assigned by MyLocals without
										restriction. Any attempted transfer or assignment in
										violation hereof shall be null and void.
									</h5>
									<h2>{'  '}</h2>
									<h5>
										<strong>Entire agreement/severability</strong> These Terms,
										together with the Privacy Policy and any amendments and any
										additional agreements you may enter into with MyLocals in
										connection with the Products, shall constitute the entire
										agreement between you and MyLocals concerning the Products.
										If any provision of these Terms is deemed invalid, then that
										provision will be limited or eliminated to the minimum
										extent necessary, and the remaining provisions of these
										Terms will remain in full force and effect.
									</h5>
									<h2>{'  '}</h2>
									<h5>
										<strong>No waiver</strong> No waiver of any term of these
										Terms shall be deemed a further or continuing waiver of such
										term or any other term, and MyLocals failure to assert any
										right or provision under these Terms shall not constitute a
										waiver of such right or provision.
									</h5>
									<h2>{'  '}</h2>
									<h5>
										<strong>Parties</strong> MyLocals.shop is a product owned by
										Weciny Inc. For the purposes of these Terms, any reference
										to &quot;MyLocals&quot; includes MyLocals.shop and all its
										worldwide subsidiaries. If you reside in Canada or any other
										country, these Terms constitute a legally binding contract
										between you and MyLocals.
									</h5>
									<h2>{'  '}</h2>
								</div>
								<br />
							</Container>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default BlogPost;
