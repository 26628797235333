import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { editProfile } from '../../../store/actions/business';

function AboutEditModal(props) {
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const dispatch = useDispatch();
	const { id } = useSelector((state) => state.currentUser.user);
	const [about, setAbout] = React.useState(props.businessAbout);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(editProfile(id, { about }));
		setAbout('');
		setModal(false);
	};
	const { businessAbout } = props;
	return (
		<div>
			<Button
				className='btn-border btn-round ml-2'
				color='default'
				outline
				type='button'
				onClick={toggle}>
				<i className='nc-icon nc-settings-gear-65 mr-1' />
				Edit
			</Button>
			<Modal isOpen={modal} toggle={toggle} {...props}>
				<ModalHeader toggle={toggle}>
					Tell your customers about your business
				</ModalHeader>

				<ModalBody>
					<Form onSubmit={handleSubmit}>
						<Input
							type='textarea'
							id='about'
							name='about'
							multiline='true'
							rows='3'
							onChange={(e) => {
								setAbout(e.target.value);
							}}
							value={about}
							placeholder={businessAbout}
						/>
						<Button color='primary' type='submit'>
							Submit
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default AboutEditModal;
