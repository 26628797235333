export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const ADD_SUCCESS = 'ADD_SUCCESS';
export const REMOVE_SUCCESS = 'REMOVE_SUCCESS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_lOGOUT = 'USER_lOGOUT';
export const LOAD_MYBUSINESS = 'LOAD_MYBUSINESS';
export const LOAD_BUSINESSES = 'LOAD_BUSINESSES';
export const LOAD_BUSINESS_IMAGE = 'LOAD_BUSINESS_IMAGE';
export const LOAD_BUSINESS_LOGO_IMAGE = 'LOAD_BUSINESS_LOGO_IMAGE';
export const POST_PRODUCT = 'POST_PRODUCT';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const POST_NEWSFEED = 'POST_NEWSFEED';
export const REMOVE_NEWSFEED = 'REMOVE_NEWSFEED';
export const LOAD_BUILDINGS = 'LOAD_BUILDINGS';
export const LOAD_BUSINESS_BUILDINGS = 'LOAD_BUSINESS_BUILDINGS';
export const REMOVE_BUILDING = 'REMOVE_BUILDING';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const SET_CART = 'SET_CART';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_CART_IS_REQUESTED = 'SET_CART_IS_REQUESTED';
export const POST_FORGET = 'POST_FORGET';
export const POST_RESET = 'POST_RESET';
