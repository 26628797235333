// src/store/reducers/cartReducer.js
import {
	SET_CART,
	SET_SESSION_ID,
	SET_CART_IS_REQUESTED,
} from '../actionTypes';

const initialState = {
	items: { items: [{ productId: { _id: '' } }] },
	sessionId: null,
	cartIsRequested: false,
};

export const shoppingCart = (state = initialState, action) => {
	switch (action.type) {
		case SET_CART:
			return {
				...state,
				items: action.payload.items,
				sessionId: action.payload.sessionId,
				cartIsRequested: action.payload.cartIsRequested,
			};
		case SET_SESSION_ID:
			return {
				...state,
				sessionId: action.payload,
			};
		case SET_CART_IS_REQUESTED:
			return {
				...state,
				cartIsRequested: action.payload, // Update the cartIsRequested status
			};
		default:
			return state;
	}
};
