import { LOAD_PRODUCT } from '../actionTypes';

const initialState = { image: [], business: {}, tags: [], categories: [] };

export const theProduct = (state = initialState, action) => {
	switch (action.type) {
		case LOAD_PRODUCT:
			return { ...action.product };
		default:
			return state;
	}
};
