import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components

function SectionComponents() {
	return (
		<>
			<div className='section section-components section-dark'>
				<Row>
					<Col className='ml-auto mr-auto' lg='4' md='10' sm='12'>
						<Container className='basic-container'>
							<h3 className='title'>
								<strong>An online local community</strong>
							</h3>
							<h6 className='category'>
								We make sure your community finds you
							</h6>
							<h5 className='description'>
								<strong>
									We understand that you&apos;re worried about the future of
									your business and we are here to help. We build online
									communities to help you build stronger connections with your
									customers.
								</strong>
							</h5>
						</Container>
					</Col>
					<Col lg='6' md='12'>
						<Container>
							<div className='image-container'>
								<img
									alt='...'
									className='components-macbook'
									src={require('../Images/card2.png')}
								/>
							</div>
						</Container>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default SectionComponents;
