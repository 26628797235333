import React, { useEffect } from 'react';
// plugin that creates slider
import Slider from 'nouislider';
import { Row } from 'reactstrap';
//Store and Redux
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchMyBusiness } from '../../store/actions/business';
import headerImage from 'assets/img/image_placeholder.jpg';
//Sections==================
import SectionLatestOffers from './Sections/LatestOffers.jsx';
// import SectionProductSearch from './Sections/ProductSearch.jsx';
import SectionBlogPosts from './Sections/BlogPosts';

// core components
import Navbar from 'views/Components/Navbar';
import Header from './Sections/Header';
import Footer from 'views/Components/Footer';

function Ecommerce(rest) {
	const dispatch = useDispatch();
	const business = useSelector((state) => state.myBusiness);
	let { businessId } = useParams();
	// const { id } = useSelector((state) => state.currentUser.user);

	useEffect(() => {
		if (businessId) {
			dispatch(fetchMyBusiness(businessId)).catch((err) => {
				console.error('Failed to fetch business data:', err);
			});
		}
	}, [businessId, dispatch]);
	document.documentElement.classList.remove('nav-open');
	useEffect(() => {
		const sliderElement = document.getElementById('sliderDouble');

		if (sliderElement && !sliderElement.classList.contains('noUi-target')) {
			Slider.create(sliderElement, {
				start: [20, 80],
				connect: [false, true, false],
				step: 1,
				range: { min: 0, max: 100 },
			});
		}

		document.body.classList.add('ecommerce-page');
		return function cleanup() {
			document.body.classList.remove('ecommerce-page');
		};
	}, []); // Empty dependency array to run this effect only once

	return (
		<>
			<Navbar businessName={business.businessName} />

			<div className='wrapper'>
				<Header
					businessName={business.businessName}
					subTitle={business.subTitle}
					headerImage={business.image[0] || headerImage}
				/>
				<div className='profile-content section'>
					<Row>
						<div className='profile-picture public-business-picture'>
							<div className='fileinput-new img-no-padding'>
								<img alt='...' src={business.logo || headerImage} />
							</div>
						</div>
					</Row>

					<SectionLatestOffers business={business} {...rest} />
					{/* <SectionProductSearch /> */}
					<SectionBlogPosts business={business} />
					<Footer />
				</div>
			</div>
		</>
	);
}

export default Ecommerce;
