import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadShoppingCart } from '../../../store/actions/purchase';
import {
	Card,
	CardBody,
	CardTitle,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
const LatestOffers = (rest) => {
	const dispatch = useDispatch();
	const cartItems = useSelector((state) => state.shoppingCart);
	useEffect(() => {
		dispatch(loadShoppingCart()).catch((err) => {
			console.error('Failed to fetch cart data:', err);
		});
	}, [dispatch]);
	const totalItemsInCart = cartItems.items.items?.reduce(
		(total, item) => total + item.quantity,
		0
	);
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	const { business } = rest;
	return (
		<div className='section latest-offers'>
			<Container style={{ maxWidth: '90%' }}>
				{rest.errors.message && (
					<div className='alert alert-danger'>{rest.errors.message}</div>
				)}
				{rest.success.message && (
					<div className='alert alert-success'>{rest.success.message}</div>
				)}
				<Row className='g-0'>
					<Col className='bg-light' xs='12' md='3'>
						<h3>About Us</h3>
						{business.about ? (
							<p className='ml-5 mt-2'>{business.about}</p>
						) : (
							<p className='ml-5 mt-2'>
								Tell your local customers a brief about yourself
							</p>
						)}
						<hr />
						<h3>Contact Us</h3>
						<p className='ml-5 mt-2'>Address: {business.address}</p>
						<p className='ml-5 mt-2'>Phone: {business.phone}</p>
						{/* <p className='ml-5 mt-2'>Website: {business.website}</p> */}
						<p className='ml-5 mt-2'>
							<i className='bi bi-globe-americas' />{' '}
							<a
								href={business.website}
								target='_block'
								style={{
									color: 'blue',
								}}>
								Our Website
							</a>
						</p>
						<hr />

						<h3 className='mb-4'>Social Media</h3>
						<Button
							className='btn-just-icon ml-3'
							color='primary'
							size='sm'
							target='_blank'
							href={business.facebook}
							type='button'>
							<i className='bi bi-facebook' />
						</Button>
						<Button
							className='btn-just-icon ml-3'
							color='warning'
							size='sm'
							target='_blank'
							href={business.instagram}
							type='button'>
							<i className='bi bi-instagram' />
						</Button>
						<Button
							className='btn-just-icon ml-3'
							target='_blank'
							color='secondary'
							href={business.twitter}
							size='sm'
							type='button'>
							<i className='bi bi-twitter-x' />
						</Button>
					</Col>
					<Col className='bg-light' xs='12' md='9'>
						<Row>
							<Col xs='6' md='6'>
								<h3 className='section-title'>Latest Offers</h3>
							</Col>
							<Col xs='6' md='6'>
								<div className='pull-right'>
									<span className='text-muted'>Order Status</span>
									<Button color='link' href='/cart'>
										<i className='fa fa-shopping-cart' /> {totalItemsInCart}{' '}
										Items
									</Button>
								</div>
							</Col>
						</Row>

						<Row>
							{business.products.map((product) => (
								<>
									<Col md='4'>
										<Card className='card-product card-plain'>
											<div className='card-image'>
												<Link
													to={{
														pathname: `/product-page/${product._id}`,
													}}>
													<img
														alt='...'
														className='img-rounded img-responsive'
														src={product.image[0]}
													/>
												</Link>
												<CardBody>
													<div className='card-description'>
														<CardTitle tag='h5'>{product.title}</CardTitle>
														<p>{product.description}</p>
													</div>
													<div className='price'>
														{/* <s className='mr-1'>3.520 €</s> */}
														<span className='text-dark'>
															CD$ {product.price}
														</span>
													</div>
												</CardBody>
											</div>
										</Card>
									</Col>
								</>
							))}
						</Row>
					</Col>
				</Row>

				{/* <Row>
					<Col md='4'>
						<Card className='card-product card-plain'>
							<div className='card-image'>
								<a href='#pablo' onClick={(e) => e.preventDefault()}>
									<img
										alt='...'
										className='img-rounded img-responsive'
										src={require('../Images/2.jpg')}
									/>
								</a>
								<CardBody>
									<div className='card-description'>
										<CardTitle tag='h5'>Green Velvet Dress</CardTitle>
										<p className='card-description'>
											This is a limited edition dress for the fall collection.
											Comes in 3 colours.
										</p>
									</div>
									<div className='price'>
										<s className='mr-1'>3.520 €</s>
										<span className='text-danger'>2.900 €</span>
									</div>
								</CardBody>
							</div>
						</Card>
					</Col>
					<Col md='4'>
						<Card className='card-product card-plain'>
							<div className='card-image'>
								<a href='#pablo' onClick={(e) => e.preventDefault()}>
									<img
										alt='...'
										className='img-rounded img-responsive'
										src={require('../Images/2.jpg')}
									/>
								</a>
								<CardBody>
									<div className='card-description'>
										<CardTitle tag='h5'>Crepe and Organza Dress</CardTitle>
										<p className='card-description'>
											This is a limited edition dress for the fall collection.
											Comes in 3 colours.
										</p>
									</div>
									<div className='price'>
										<s className='mr-1'>2.675 €</s>
										<span className='text-danger'>2.000 €</span>
									</div>
								</CardBody>
							</div>
						</Card>
					</Col>
					<Col md='4'>
						<Card className='card-product card-plain'>
							<div className='card-image'>
								<a href='#pablo' onClick={(e) => e.preventDefault()}>
									<img
										alt='...'
										className='img-rounded img-responsive'
										src={require('../Images/2.jpg')}
									/>
								</a>
								<CardBody>
									<div className='card-description'>
										<CardTitle tag='h5'>Lace Mini Dress</CardTitle>
										<p className='card-description'>
											This is a limited edition dress for the fall collection.
											Comes in 3 colours.
										</p>
									</div>
									<div className='price'>
										<s className='mr-1'>3.915 €</s>
										<span className='text-danger'>3.125 €</span>
									</div>
								</CardBody>
							</div>
						</Card>
					</Col>
				</Row> */}
			</Container>
		</div>
	);
};

export default LatestOffers;
