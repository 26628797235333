import React, { useEffect } from 'react';
import { Route, Navigate, Routes, useLocation } from 'react-router-dom';
import LandingPage from 'views/LandingPage/index';
import SignupPage from 'views/SignupPage/index';
import SigninPage from 'views/SigninPage/index';
import AboutPage from 'views/AboutPage/index';
import TermsPage from 'views/TermsPage/index';
import PricingPage from 'views/PricingPage/index';
import ContactUsPage from 'views/ContactUsPage/index';
import ForgotPassword from 'views/ResetPassword/forgotPassword.jsx';
import ResetPassword from 'views/ResetPassword/resetPassword.jsx';
import BusinessPage from 'views/BusinessPage/index';
import BusinessPublicPage from 'views/BusinessPublicPage/index';
import BuildingSearchPage from 'views/BuildingSearchPage/index';
import ProductPageAdmin from 'views/ProductPageAdmin/index';
import ProductPage from 'views/ProductPage/index';
import CartPage from 'views/CartPage/index';
import ErrorPage from 'views/ErrorPage/index';
import { useSelector } from 'react-redux';
import { removeError } from '../store/actions/errors';
import { removeSuccess } from '../store/actions/success';

// pages for this product

const Main = (props) => {
	const currentUser = useSelector((state) => state.currentUser);
	const errors = useSelector((state) => state.errors);
	const success = useSelector((state) => state.success);
	const { pathname } = useLocation();
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);
	return (
		<Routes>
			<Route path='*' element={<Navigate to='/' />} />
			<Route
				exact
				path='/'
				element={
					!currentUser.isAuthenticated ? (
						<LandingPage
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>

			<Route
				exact
				path='/signup'
				element={
					!currentUser.isAuthenticated ? (
						<SignupPage
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>
			<Route
				exact
				path='/signin'
				element={
					!currentUser.isAuthenticated ? (
						<SigninPage
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>
			<Route
				exact
				path='/about-us'
				element={
					!currentUser.isAuthenticated ? (
						<AboutPage
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>
			<Route
				exact
				path='/terms'
				element={
					!currentUser.isAuthenticated ? (
						<TermsPage
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>
			<Route
				exact
				path='/pricing'
				element={
					!currentUser.isAuthenticated ? (
						<PricingPage
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>
			<Route
				exact
				path='/contact-us'
				element={
					!currentUser.isAuthenticated ? (
						<ContactUsPage
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>
			<Route
				exact
				path='/forgot'
				element={
					!currentUser.isAuthenticated ? (
						<ForgotPassword
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>
			<Route
				exact
				path='/reset-password/:token'
				element={
					!currentUser.isAuthenticated ? (
						<ResetPassword
							errors={errors}
							removeError={removeError}
							success={success}
							removeSuccess={removeSuccess}
							{...props}
						/>
					) : (
						<Navigate to='/business-page' />
					)
				}
			/>
			{/* =================================E-commerce Public Pages==================================== */}
			<Route
				exact
				path='/business-page/:businessId'
				element={
					<BusinessPublicPage
						errors={errors}
						removeError={removeError}
						success={success}
						removeSuccess={removeSuccess}
						{...props}
					/>
				}
			/>
			<Route
				path='/product-page/:productId'
				element={
					<ProductPage
						errors={errors}
						removeError={removeError}
						success={success}
						removeSuccess={removeSuccess}
						{...props}
					/>
				}
			/>
			{/* =========================================================================================== */}
			<Route path='/business-page' element={<BusinessPage />} />
			<Route path='/building-search-page' element={<BuildingSearchPage />} />
			<Route
				path='/my-product-page/:productId'
				element={<ProductPageAdmin />}
			/>
			<Route
				path='/cart'
				element={
					<CartPage
						errors={errors}
						removeError={removeError}
						success={success}
						removeSuccess={removeSuccess}
						{...props}
					/>
				}
			/>
			<Route path='*' element={<ErrorPage />} />
		</Routes>
	);
};
export default Main;
