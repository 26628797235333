import React, { useState, useRef } from 'react';

import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { postProduct } from '../../../store/actions/business';
import * as yup from 'yup';
// import ImageInput from '../../Components/ImageInput';
const FILE_SIZE = 1e6;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
const schema = yup.object({
	// title: yup.string().required('required'),
	// description: yup.string().required('required'),
	files: yup
		.mixed()
		.nullable()
		.required('Image is required')
		.test(
			'fileSize',
			'File too large, select Images smaller than 1MB',
			(value) => value && value.size <= FILE_SIZE
		)
		.test(
			'fileFormat',
			'Unsupported Format',
			(value) => value && SUPPORTED_FORMATS.includes(value.type)
		),
});

function AboutEditModal(props) {
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const dispatch = useDispatch();
	const { id } = useSelector((state) => state.currentUser.user);
	const [title, setTitle] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [price, setPrice] = React.useState(null);
	const [files, setFiles] = React.useState([]);
	const [messageImage, setMessageImage] = React.useState(
		'Please Select Images with the size of up to 1MB'
	);
	const [validIt, setValidIt] = React.useState(undefined);
	const inputFile = useRef(null);
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			postProduct(id, {
				title,
				files,
				price,
				description,
			})
		);
		setTitle('');
		setFiles([]);
		setPrice(null);
		setDescription('');
		setModal(false);
		setValidIt(undefined);
	};
	//handeling images upload
	const handleImageChange = (e) => {
		setFiles(validateFiles(e.target.files));
		setValidIt(undefined);
		setMessageImage('');
		schema
			.validate({
				files: e.target.files[0],
			})
			.catch((err) => {
				setFiles([]);
				setMessageImage('');
				setValidIt(err.errors);
			});
	};
	const validateFiles = (file) => {
		return [...files, ...file];
	};
	return (
		<div>
			<Button className='btn-round' color='info' type='button' onClick={toggle}>
				<i className='bi bi-plus-circle mr-2' />
				New Product
			</Button>
			<Modal size='xl' isOpen={modal} toggle={toggle} {...props}>
				<ModalHeader toggle={toggle}>
					Tell your customers about your business
				</ModalHeader>

				<Form onSubmit={handleSubmit}>
					<ModalBody>
						<Row>
							<Col md='4'>
								<h4>Select Images</h4>
								<h6>{messageImage}</h6>
								<div className='product-fileinput text-center'>
									<Row>
										{files.map((image, index) => (
											<Col key={index} md='6'>
												<div className='thumbnail'>
													<img src={URL.createObjectURL(image)} alt='preview' />
												</div>
											</Col>
										))}
										{validIt && (
											<Col>
												<h6 className='errorMessage'>{validIt}</h6>
											</Col>
										)}
									</Row>
									{files.length > 0 && (
										<Button
											className='btn-round'
											color='default'
											outline
											onClick={() => {
												inputFile.current.value = '';
												setFiles([]);
											}}>
											Remove Images
										</Button>
									)}
									<Button
										className='btn-round'
										color='info'
										outline
										onClick={() => inputFile.current.click()}>
										Select Images
									</Button>
									<input
										id='files'
										ref={inputFile}
										style={{ display: 'none' }}
										type='file'
										multiple
										onChange={handleImageChange}
									/>
								</div>
							</Col>
							<Col md='8'>
								<Input
									type='text'
									id='title'
									name='title'
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									placeholder='Title'
									required
								/>
								<Input
									type='text'
									id='description'
									name='description'
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									placeholder='Description'
									required
									className='mt-3'
								/>
								<Input
									type='number'
									id='price'
									name='price'
									value={price}
									onChange={(e) => setPrice(e.target.value)}
									placeholder='Price'
									required
									className='mt-3'
								/>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color='primary' type='submit'>
							Submit
						</Button>
						<Button color='secondary' onClick={toggle}>
							Cancel
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</div>
	);
}

export default AboutEditModal;
