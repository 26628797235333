import { apiCall } from '../../services/api';
import { addError } from './errors';
import { addSuccess } from './success';
import {
	LOAD_BUILDINGS,
	LOAD_BUSINESS_BUILDINGS,
	REMOVE_BUILDING,
} from '../actionTypes';

// TO GET ALL BUILDINGS
export const loadedBuildings = (buildings) => ({
	type: LOAD_BUILDINGS,
	buildings,
});

export const fetchAllBuildings = () => async (dispatch, getState) => {
	const { currentUser } = getState();
	const businessId = currentUser.user.id;

	try {
		const res = await apiCall('get', `/api/business/buildings/${businessId}`);
		dispatch(loadedBuildings(res));
	} catch (err) {
		dispatch(addError(err?.message));
	}
};

export const loadBusinessBuildings = (buildings) => ({
	type: LOAD_BUSINESS_BUILDINGS,
	buildings,
});

export const fetchMyBusinessBuildings = (businessId) => async (dispatch) => {
	try {
		const res = await apiCall('get', `/api/business/${businessId}/buildings`);
		dispatch(loadBusinessBuildings(res));
	} catch (err) {
		dispatch(addError(err.message));
	}
};

export const addBuilding = (businessId, buildingId) => async (dispatch) => {
	try {
		const res = await apiCall(
			'post',
			`/api/business/${businessId}/${buildingId}/add`
		);
		dispatch(loadBusinessBuildings(res));
		dispatch(addSuccess('You have added a building to your business!!'));
	} catch (err) {
		dispatch(addError(err.message));
	}
};

export const removeBuilding = (businessId, buildingId) => async (dispatch) => {
	try {
		const res = await apiCall(
			'post',
			`/api/business/${businessId}/${buildingId}/remove`
		);
		dispatch({ type: REMOVE_BUILDING, buildingId });
		dispatch(
			addSuccess('Building successfully was removed from your account!!')
		);
		console.log(res);
	} catch (err) {
		dispatch(addError(err.message));
	}
};
