import { combineReducers } from 'redux';
import currentUser from './currentUser';
import errors from './errors';
import success from './success';
import { myBusiness } from './business';
import { businesses } from './business';
import { theProduct } from './product';
import { allBuildings } from './building';
import { businessBuildings } from './building';
import { shoppingCart } from './purchase';
import { resetReducer } from './user';

const rootReducer = combineReducers({
	currentUser,
	errors,
	success,
	myBusiness,
	businesses,
	theProduct,
	allBuildings,
	businessBuildings,
	shoppingCart,
	resetReducer,
});

export default rootReducer;
