import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	Collapse,
	CardHeader,
	Button,
	Nav,
	NavItem,
} from 'reactstrap';
//Redux Store===========================================
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyBusiness } from '../../../store/actions/business';
import AboutEditModal from '../Modals/AboutEdit';
import ContactEditModal from '../Modals/ContactEdit';
import SocialMediaEditModal from '../Modals/SocialMediaEdit';

const SideBar = (props) => {
	const dispatch = useDispatch();
	const business = useSelector((state) => state.myBusiness);
	// const errors = useSelector((state) => state.errors);
	const { id } = useSelector((state) => state.currentUser.user);
	const [sidebarIsOpen, setSidebarOpen] = useState(true);
	const toggleSidebar = () => {
		setSidebarOpen(!sidebarIsOpen);
	};
	const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

	useEffect(() => {
		dispatch(fetchMyBusiness(id));

		const handleResize = () => setIsMobile(window.innerWidth < 720);
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [dispatch, id]);

	useEffect(() => {
		setSidebarOpen(!isMobile);
	}, [isMobile]);
	console.log(business.website);
	return (
		<div className='sideBar'>
			<React.StrictMode>
				{isMobile && (
					<Button
						color='default'
						onClick={toggleSidebar}
						style={{ marginBottom: '1rem', marginTop: '0px' }}>
						<i className='bi bi-layout-text-sidebar' />
					</Button>
				)}
				<div className='side-menu'>
					<Collapse isOpen={sidebarIsOpen} {...props}>
						<Card className='mt-2'>
							<CardHeader>
								<h3>
									<strong>We are {business.businessName}</strong>
								</h3>
							</CardHeader>
							<CardBody>
								<Nav vertical>
									<NavItem>
										<h4>
											<strong>About Us:</strong>
										</h4>
										{business.about ? (
											<p className='ml-5 mt-2'>{business.about}</p>
										) : (
											<p className='ml-5 mt-2'>
												Tell your local customers a brief about yourself
											</p>
										)}
										<AboutEditModal businessAbout={business.about} />
									</NavItem>
									<hr />
									<NavItem>
										<h4>
											<strong>Contact Us:</strong>
										</h4>
										<p className='ml-5 mt-2'>
											<i className='bi bi-geo-alt-fill' /> {'  '}Address:{' '}
											{business.address}
										</p>
										<p className='ml-5 mt-2'>
											<i className='bi bi-phone-fill' />
											{'  '}
											Phone: {business.phone}
										</p>
										<p className='ml-5 mt-2'>
											<i className='bi bi-globe-americas' />{' '}
											<a
												href={business.website}
												target='_block'
												style={{
													color: 'blue',
												}}>
												Our Website
											</a>
										</p>
										<ContactEditModal
											valuePhone={business.phone}
											valueWebsite={business.website}
										/>
									</NavItem>
									<hr />
									<NavItem>
										<h4 className='mb-4'>
											<strong>Social Media:</strong>
										</h4>
										<Button
											className='btn-just-icon ml-3'
											color='primary'
											size='sm'
											target='_blank'
											href={business.facebook}
											type='button'>
											<i className='bi bi-facebook' />
										</Button>
										<Button
											className='btn-just-icon ml-3'
											color='warning'
											size='sm'
											target='_blank'
											href={business.instagram}
											type='button'>
											<i className='bi bi-instagram' />
										</Button>
										<Button
											className='btn-just-icon ml-3'
											target='_blank'
											color='secondary'
											href={business.twitter}
											size='sm'
											type='button'>
											<i className='bi bi-twitter-x' />
										</Button>
										<SocialMediaEditModal
											valueTwitter={business.twitter}
											valueInstagram={business.instagram}
											valueFacebook={business.facebook}
										/>
									</NavItem>
									<hr />
								</Nav>
							</CardBody>
						</Card>
					</Collapse>
				</div>
			</React.StrictMode>
		</div>
	);
};

export default SideBar;
