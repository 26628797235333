import React, { useState, useRef } from 'react';
import { editHeaderImage } from '../../../store/actions/business';
import { useSelector, useDispatch } from 'react-redux';
import { Button, UncontrolledTooltip, Col } from 'reactstrap';
import headerImage from 'assets/img/image_placeholder.jpg';

import * as yup from 'yup';
// import ImageInput from '../../Components/ImageInput';
const FILE_SIZE = 1e6;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const schema = yup.object({
	// title: yup.string().required('required'),
	// description: yup.string().required('required'),
	files: yup
		.mixed()
		.nullable()
		.required('Image is required')
		.test(
			'fileSize',
			'File too large, select Images smaller than 1MB',
			(value) => value && value.size <= FILE_SIZE
		)
		.test(
			'fileFormat',
			'Unsupported Format',
			(value) => value && SUPPORTED_FORMATS.includes(value.type)
		),
});

function ProfilePageHeader(props) {
	const dispatch = useDispatch();
	const [image, setImage] = useState(undefined);
	const [validIt, setValidIt] = React.useState(undefined);
	const { id } = useSelector((state) => state.currentUser.user);
	const business = props.business;
	const inputFile = useRef(null);
	const handleClickImage = () => {
		dispatch(editHeaderImage(id, image));
		setImage(undefined);
	};
	const handleClearInputFile = () => {
		inputFile.current.value = '';
		setImage('');
	};
	document.documentElement.classList.remove('nav-open');
	React.useEffect(() => {
		document.body.classList.add('profile-page');
		return function cleanup() {
			document.body.classList.remove('profile-page');
		};
	});
	return (
		<>
			<div
				className='page-header page-header-xs'
				style={{
					backgroundImage: `url(${business.image[0] || headerImage})`,
				}}>
				<div className='filter' />
			</div>
			<div className='editHeaderImageButton'>
				{validIt && (
					<Col xs={12} md={12} sm={12}>
						<h6 className='errorMessageHeaderImage'>{validIt}</h6>
					</Col>
				)}
				{!image && (
					<>
						<Button
							className='btn-just-icon mt-5'
							color='success'
							id='tooltip4370363'
							size='sm'
							onClick={() => inputFile.current.click()}
							type='button'>
							<i className='nc-icon nc-settings-gear-65' />
						</Button>
						<UncontrolledTooltip
							delay={0}
							placement='right'
							target='tooltip4370363'>
							Edit the header image
						</UncontrolledTooltip>
					</>
				)}

				<input
					ref={inputFile}
					type='file'
					style={{ display: 'none' }}
					onChange={(e) => {
						setImage(e.target.files[0]);
						setValidIt(undefined);
						schema
							.validate({
								files: e.target.files[0],
							})
							.catch((err) => {
								setImage(undefined);
								setValidIt(err.errors);
							});
					}}
				/>
				{image && (
					<>
						<div className='post-preview'>
							<img
								className='post-preview-img'
								src={URL.createObjectURL(image)}
								alt='preview'
							/>
							<i onClick={handleClearInputFile} className='bi bi-x-circle' />
							<i onClick={handleClickImage} className='bi bi-check-circle' />
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default ProfilePageHeader;
