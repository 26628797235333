import React from 'react';
import withAuth from '../../hocs/withAuth';

import { Row, Col } from 'reactstrap';
import Navbar from '../Components/NavbarAuth';
import SideBar from './Sections/Sidebar';
import SectionProducts from './Sections/SectionProducts';
import './index.css'; // Import custom styles for additional CSS

const BusinessPage = (rest) => {
	return (
		<div>
			<Navbar {...rest} />
			<Row className='g-0'>
				<Col className='bg-light' xs='12' md='3'>
					<SideBar />
				</Col>
				<Col className='bg-light' xs='12' md='9'>
					<SectionProducts {...rest} />
				</Col>
			</Row>
		</div>
	);
};
export default withAuth(BusinessPage);
