import {
	LOAD_BUILDINGS,
	LOAD_BUSINESS_BUILDINGS,
	REMOVE_BUILDING,
} from '../actionTypes';

//All buildings
const initialState = [];
export const allBuildings = (state = initialState, action) => {
	switch (action.type) {
		case LOAD_BUILDINGS:
			return [...action.buildings];
		default:
			return state;
	}
};
// Business buildings
const initialState2 = [{ image: [] }];

export const businessBuildings = (state = initialState2, action) => {
	switch (action.type) {
		case LOAD_BUSINESS_BUILDINGS:
			return [...action.buildings];
		case REMOVE_BUILDING:
			return state.filter((building) => building._id !== action.buildingId); // Remove a building by ID

		default:
			return state;
	}
};
