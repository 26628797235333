import React from 'react';
import BlogPostForms from '../Modals/BlogPostForms.js';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeNewsFeed } from '../../../store/actions/business';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardFooter,
	CardTitle,
	Button,
} from 'reactstrap';

function BlogPostSection() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const business = useSelector((state) => state.myBusiness);
	const newsFeeds = business.newsFeeds;
	console.log(newsFeeds);
	return (
		<>
			<br />
			<div className='section section-gray'>
				<Container>
					<Row>
						<Col>
							<h3 className='section-title mb-5'>
								<strong>Marketing Blog Posts</strong>
							</h3>
						</Col>
						<Col>
							<BlogPostForms />
						</Col>
					</Row>
					<Row>
						{newsFeeds.map((feed) => (
							<Col md='4' key={feed._id}>
								<Card className='card-blog'>
									<div className='card-image'>
										<a href='#pablo' onClick={(e) => e.preventDefault()}>
											<img
												alt='...'
												className='img img-raised'
												src={feed.image[0]}
											/>
										</a>
									</div>
									<CardBody>
										{/* <h6 className='card-category text-info'>Enterprise</h6> */}
										<CardTitle tag='h5'>
											<a href='#pablo' onClick={(e) => e.preventDefault()}>
												{feed.title}
											</a>
										</CardTitle>
										<p className='card-description'>
											{feed.description} <br />
										</p>
										<hr />
										<CardFooter>
											<Button
												onClick={() => {
													dispatch(removeNewsFeed(feed._id));
													navigate({
														pathname: '/business-page',
														state: 'Item Deleted',
													});
												}}
												round
												color='danger'>
												Remove
											</Button>
										</CardFooter>
									</CardBody>
								</Card>
							</Col>
						))}
					</Row>
				</Container>
			</div>
			{/* section */}
		</>
	);
}

export default BlogPostSection;
