import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authUserSignin } from '../../store/actions/auth';
// reactstrap components
import {
	Button,
	Card,
	CardTitle,
	Form,
	Input,
	Container,
	Row,
	Col,
	FormFeedback,
} from 'reactstrap';
import Navbar from '../Components/Navbar';
import * as yup from 'yup';
import { useFormik } from 'formik';
//Yup Object ==================================================
const schema = yup.object({
	email: yup
		.string()
		.email('Enter a valid email')
		.required('Email is required'),
	password: yup.string('').required('Enter your password'),
});
// core components
function LoginPage(rest) {
	const dispatch = useDispatch();
	document.documentElement.classList.remove('nav-open');
	useEffect(() => {
		document.body.classList.add('login-page');
		document.body.classList.add('full-screen');
		return function cleanup() {
			document.body.classList.remove('login-page');
			document.body.classList.remove('full-screen');
		};
	}, []);
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	//Formik setup=====================================
	const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: schema,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (evt) => {
			try {
				dispatch(authUserSignin(evt));
			} catch (err) {
				console.log(err);
			}
		},
	});

	return (
		<>
			<Navbar />
			<div className='wrapper'>
				<div
					className='page-header'
					style={{
						backgroundImage: 'url(' + require('../Images/5.jpg') + ')',
					}}>
					<div className='filter' />
					<Container>
						<Row>
							<Col className='ml-auto mr-auto' lg='6' md='7' sm='7'>
								<Card className='card-register'>
									{rest.errors.message && (
										<div className='alert alert-danger'>
											{rest.errors.message}
										</div>
									)}
									{rest.success.message && (
										<div className='alert alert-success'>
											{rest.success.message}
										</div>
									)}
									<CardTitle tag='h3'>Welcome</CardTitle>
									<Form onSubmit={handleSubmit} className='register-form'>
										<label>Email</label>
										<Input
											type='email'
											id='email'
											name='email'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											placeholder='Email'
										/>
										<FormFeedback>
											<strong>{errors.email}</strong>
										</FormFeedback>
										<label>Password</label>
										<Input
											type='password'
											id='password'
											name='password'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password}
											placeholder='Password'
										/>
										<FormFeedback>
											<strong>{errors.password}</strong>
										</FormFeedback>
										<Button
											block
											className='btn-round'
											color='success'
											type='submit'>
											Login
										</Button>
									</Form>
									<div className='forgot'>
										<Button className='btn-link' color='danger' href='/forgot'>
											Forgot password?
										</Button>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
					<div className='demo-footer text-center'>
						<h6>
							© {new Date().getFullYear()}, made with{' '}
							<i className='fa fa-heart heart' /> by Creative Tim
						</h6>
					</div>
				</div>
				{/* <Footer /> */}
			</div>
		</>
	);
}

export default LoginPage;
