import {
	LOAD_MYBUSINESS,
	POST_PRODUCT,
	REMOVE_NEWSFEED,
	POST_NEWSFEED,
	LOAD_BUSINESSES,
	LOAD_BUSINESS_IMAGE,
	LOAD_BUSINESS_LOGO_IMAGE,
} from '../actionTypes';
import headerImage from 'assets/img/image_placeholder.jpg';
const initialState = {
	products: [{ image: [headerImage], price: 0, _id: 0, description: '' }],
	newsFeeds: [{ image: [headerImage] }],
	image: [headerImage],
};
export const myBusiness = (state = initialState, action) => {
	switch (action.type) {
		case LOAD_MYBUSINESS:
			return { ...action.myBusiness };
		case POST_PRODUCT:
			return { ...state, products: [...state.products, action.newProduct] };
		case POST_NEWSFEED:
			return {
				...state,
				// Adding new newsFeed to the existing newsFeeds array immutably
				newsFeeds: [...state.newsFeeds, action.newsFeed],
			};
		case REMOVE_NEWSFEED:
			return {
				...state,
				newsFeeds: state.newsFeeds.filter(
					(newsFeed) => newsFeed._id !== action.id
				),
			};
		case LOAD_BUSINESS_IMAGE:
			return { ...state, image: [action.image] };
		case LOAD_BUSINESS_LOGO_IMAGE:
			return { ...state, logo: action.image };
		default:
			return state;
	}
};

//reducer for loading businesses ==========================================================
export const businesses = (state = [], action) => {
	switch (action.type) {
		case LOAD_BUSINESSES:
			return [...action.businesses];
		default:
			return state;
	}
};
