/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Button, Col, Row } from 'reactstrap';
// core comments

function SectionHeader() {
	return (
		<>
			<div className='wrapper text-left'>
				<div
					className='page-header'
					style={{
						backgroundImage:
							'url(' +
							require('views/LandingPage/Images/landing-background.jpg') +
							')',
					}}>
					<div className='filter' />
					<div className='content-center'>
						<Container>
							<Row>
								<Col className='text-left' md='7'>
									<h2 className='title-uppercase'>
										<strong>Build your Online Store with us</strong>
									</h2>
									<h3 className='title'>
										<strong>And we connect you to local customers.</strong>
									</h3>
									<br />
									<Button
										className='btn-round mr-1'
										color='danger'
										href='/signup'>
										get started
									</Button>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
}

export default SectionHeader;
