import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { postReset } from '../../store/actions/user';

// reactstrap components
import {
	Button,
	Card,
	CardTitle,
	Form,
	Input,
	Container,
	Row,
	Col,
} from 'reactstrap';

// core components
import Navbar from '../Components/Navbar';

function LoginPage(rest) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let { token } = useParams();
	const reset = useSelector((state) => state.resetReducer);
	const [password, setPassword] = useState();
	const [confirm, setConfirm] = useState();

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(postReset(token, { password, confirm }));
		setConfirm('');
		setPassword('');
		navigate(`/reset-password/${token}`);
	};
	document.documentElement.classList.remove('nav-open');
	useEffect(() => {
		document.body.classList.add('login-page');
		document.body.classList.add('full-screen');
		return function cleanup() {
			document.body.classList.remove('login-page');
			document.body.classList.remove('full-screen');
		};
	}, []);
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	return (
		<>
			<Navbar />
			<div className='wrapper'>
				<div
					className='page-header'
					style={{
						backgroundImage:
							'url(' + require('assets/img/sections/david-marcu.jpg') + ')',
					}}>
					<div className='filter' />
					<Container>
						<Row>
							<Col className='ml-auto mr-auto' lg='4' md='6' sm='6'>
								<Card className='card-register'>
									{reset && (
										<div className='alert alert-info'>
											{reset.forgetMessage}
										</div>
									)}
									{rest.errors.message && (
										<div className='alert alert-danger'>
											{rest.errors.message}
										</div>
									)}
									{rest.success.message && (
										<div className='alert alert-success'>
											{rest.success.message}
										</div>
									)}
									<CardTitle tag='h3'>Your Email Address</CardTitle>
									<Form className='register-form' onSubmit={handleSubmit}>
										<label>Email</label>

										<Input
											className='no-border'
											autoComplete='off'
											id='password'
											name='password'
											onChange={(e) => setPassword(e.target.value)}
											type='password'
											value={password}
											placeholder='New password'
											required
										/>
										<label>Email</label>
										<Input
											className='no-border'
											autoComplete='off'
											id='confirm'
											name='confirm'
											onChange={(e) => setConfirm(e.target.value)}
											type='password'
											value={confirm}
											placeholder='Confirm your password'
											required
										/>

										<Button color='info' size='sm'>
											Submit
										</Button>
									</Form>
								</Card>
							</Col>
						</Row>
						<div className='demo-footer text-center'>
							<h6>
								© {new Date().getFullYear()}, made with{' '}
								<i className='fa fa-heart heart' /> by Weciny
							</h6>
						</div>
					</Container>
				</div>
			</div>
		</>
	);
}

export default LoginPage;
