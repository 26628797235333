import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// reactstrap components
import {
	Button,
	Label,
	FormGroup,
	Input,
	Container,
	Row,
	Col,
	FormFeedback,
	Form,
} from 'reactstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
//Store actions
import { authUserSignup } from '../../../store/actions/auth';
//Yup Object ==================================================
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const schema = yup.object({
	firstName: yup.string().required('required'),
	lastName: yup.string().required('required'),
	businessName: yup.string().trim().required('required'),
	email: yup
		.string()
		.email('Enter a valid email')
		.required('Email is required'),
	businessPhone: yup.number().required('required'),
	address: yup.string().required('required'),
	postalCode: yup
		.string()
		.min(6, 'postal code must contain at least 6 characters')
		.required('required'),
	businessType: yup.string().required('Please Select a business type'),
	businessSector: yup.string().required('Please select the sector'),
	password: yup
		.string('')
		.min(8, 'Password must contain at least 8 characters')
		.matches(passwordRules, {
			message: 'password must include lowercase upper case letters and number',
		})
		.required('Enter your password'),
	confirmPassword: yup
		.string('Enter your password')
		.required('Please Confirm your password')
		.oneOf([yup.ref('password')], 'Password does not match'),
});
// ====================================================

function SignUpForms(rest) {
	const dispatch = useDispatch();
	document.documentElement.classList.remove('nav-open');
	useEffect(() => {
		document.body.classList.add('add-product');
		return () => {
			document.body.classList.remove('add-product');
		};
	}, []);
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	//Formik setup=====================================
	const {
		values,
		errors,
		handleChange,
		handleSubmit,
		validateForm,
		validateField,
	} = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			businessName: '',
			email: '',
			businessPhone: '',
			address: '',
			postalCode: '',
			businessType: '0',
			businessSector: '0',
			password: '',
			confirmPassword: '',
		},
		validationSchema: schema,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (evt) => {
			dispatch(authUserSignup(evt));
		},
	});
	const preHandleSubmit = async (e) => {
		e.preventDefault();
		const isValid = await validateForm();
		if (!Object.keys(isValid).length) {
			handleSubmit();
		}
	};
	return (
		<>
			<div>
				<div className='section signUpPageMain'>
					<Container>
						{rest.errors.message && (
							<div className='alert alert-danger'>{rest.errors.message}</div>
						)}
						{rest.success.message && (
							<div className='alert alert-success'>{rest.success.message}</div>
						)}
						<h2>
							<strong>Register</strong>
						</h2>
						<div>
							<Row>
								<Col md='5' sm='5'>
									<div className='info info-horizontal'>
										<div className='icon'>
											<i className='bi bi-shop' />
										</div>
										<div className='description'>
											<h3>
												<strong>How we help you</strong>
											</h3>
											<p>
												<strong>
													We help you showcase your products online
												</strong>
											</p>
										</div>
									</div>
									<div className='info info-horizontal'>
										<div className='icon'>
											<i className='bi bi-building-fill-check' />
										</div>
										<div className='description'>
											<h3>
												<strong>How we help you market to locals</strong>
											</h3>
											<p>
												<strong>
													Our partner Weciny connects you locals in you area.
												</strong>
											</p>
										</div>
									</div>
									<div className='info info-horizontal'>
										<div className='icon'>
											<i className='bi bi-fingerprint' />
										</div>
										<div className='description'>
											<h3>
												<strong>Feel secure with us</strong>
											</h3>
											<p>
												<strong>
													We protect your information using the latest
													technologies
												</strong>
											</p>
										</div>
									</div>
								</Col>
								<Col md='7' sm='7'>
									<Form onSubmit={preHandleSubmit}>
										<FormGroup>
											<Row>
												<Col md={6}>
													<Input
														invalid={errors.firstName ? true : false}
														type='text'
														id='firstName'
														name='firstName'
														onChange={handleChange}
														onBlur={() => validateField('firstName')}
														value={values.firstName}
														placeholder='First Name'
													/>
													<FormFeedback>
														<strong>{errors.firstName}</strong>
													</FormFeedback>
												</Col>
												<Col md={6}>
													<Input
														invalid={errors.lastName ? true : false}
														type='text'
														id='lastName'
														name='lastName'
														onChange={handleChange}
														onBlur={() => validateField('lastName')}
														value={values.lastName}
														placeholder='last Name'
													/>
													<FormFeedback>{errors.lastName}</FormFeedback>
												</Col>
											</Row>
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.email ? true : false}
												type='email'
												id='email'
												name='email'
												onChange={handleChange}
												onBlur={() => validateField('email')}
												value={values.email}
												placeholder='Email'
											/>
											<FormFeedback>{errors.email}</FormFeedback>
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.businessName ? true : false}
												type='text'
												id='businessName'
												name='businessName'
												onChange={handleChange}
												onBlur={() => validateField('businessName')}
												value={values.businessName}
												placeholder='Business Name'
											/>
											<FormFeedback>{errors.businessName}</FormFeedback>
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.businessPhone ? true : false}
												type='number'
												id='businessPhone'
												name='businessPhone'
												onChange={handleChange}
												onBlur={() => validateField('businessPhone')}
												value={values.businessPhone}
												placeholder='Business Phone Number'
											/>
											<FormFeedback>{errors.businessPhone}</FormFeedback>
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.address ? true : false}
												type='text'
												id='address'
												name='address'
												onChange={handleChange}
												onBlur={() => validateField('address')}
												value={values.address}
												placeholder='Business Address'
											/>
											<FormFeedback>{errors.address}</FormFeedback>
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.postalCode ? true : false}
												type='text'
												id='postalCode'
												name='postalCode'
												onChange={handleChange}
												onBlur={() => validateField('postalCode')}
												value={values.postalCode}
												placeholder='Postal Code'
											/>
											<FormFeedback>{errors.postalCode}</FormFeedback>
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.businessType ? true : false}
												id='businessType'
												name='businessType'
												type='select'
												onChange={handleChange}
												onBlur={() => validateField('businessType')}
												value={values.businessType}
												placeholder='Business Type'>
												<option>Sole preparation</option>
												<option>Partnership</option>
												<option>Corporation</option>
											</Input>
											<FormFeedback>{errors.businessType}</FormFeedback>
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.businessSector ? true : false}
												id='businessSector'
												name='businessSector'
												type='select'
												onChange={handleChange}
												onBlur={() => validateField('businessSector')}
												value={values.businessSector}
												placeholder='Business Sector'>
												<option>Apparel</option>
												<option>Beauty</option>
												<option>Construction/ Contractor</option>
												<option>Cleaning Services</option>
												<option>Baby Sitter</option>
												<option>Creative/ Cultural</option>
												<option>Dog Walker</option>
												<option>Health and wellness</option>
												<option>Jewelry</option>
												<option>Furniture</option>
												<option>Restaurant</option>
												<option>Sport / Trainer</option>
												<option>Yoga teacher</option>
												<option>Food and drinks</option>
												<option>Other Services</option>
											</Input>
											<FormFeedback>{errors.businessSector}</FormFeedback>
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.password ? true : false}
												type='password'
												id='password'
												name='password'
												onChange={handleChange}
												onBlur={() => validateField('password')}
												value={values.password}
												placeholder='Choose a password'
											/>
											{errors.password ? (
												<FormFeedback>{errors.password}</FormFeedback>
											) : (
												<></>
											)}
										</FormGroup>
										<FormGroup>
											<Input
												invalid={errors.confirmPassword ? true : false}
												type='password'
												id='confirmPassword'
												name='confirmPassword'
												onChange={handleChange}
												onBlur={() => validateField('confirmPassword')}
												value={values.confirmPassword}
												placeholder='Confirm your Password'
											/>
											<FormFeedback>{errors.confirmPassword}</FormFeedback>
										</FormGroup>
										<FormGroup>
											<input id='checkbox2' type='checkbox' required />{' '}
											<Label check>
												I agree to the{' '}
												<a className='login' href='/terms'>
													terms and conditions
												</a>
												.
											</Label>
										</FormGroup>
										<Button className='btn-round' color='success'>
											Register
										</Button>
									</Form>
									<div className='login mb-5'>
										<p>
											Already have an account? <a href='/signin'>Log in</a>.
										</p>
									</div>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</div>
		</>
	);
}

export default SignUpForms;
