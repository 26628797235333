import { POST_FORGET, POST_RESET } from '../actionTypes';

const initialState = {
	forgetMessage: '',
	resetMessage: '',
};

export const resetReducer = (state = initialState, action) => {
	switch (action.type) {
		case POST_FORGET:
			return { ...state, forgetMessage: action.message };
		case POST_RESET:
			return { ...state, resetMessage: action.message };
		default:
			return state;
	}
};
