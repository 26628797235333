import React, { useEffect, useState } from 'react';
// core components
import { useDispatch } from 'react-redux';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { postContact } from '../../store/actions/business';
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
} from 'reactstrap';

function SectionContactUs(rest) {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [text, setText] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(postContact({ email, name, phone, text }));
		setEmail('');
		setName('');
		setPhone('');
		setText('');
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	return (
		<>
			<div id='contact-us'>
				<div
					className='contactus-1 section-image'
					style={{
						backgroundImage: 'url(' + require('../Images/3.jpg') + ')',
					}}>
					<Container>
						<Navbar />
						<Row>
							<Col className='ml-auto mr-auto' md='10'>
								<Card className='card-contact no-transition'>
									{rest.errors.message && (
										<div className='alert alert-danger'>
											{rest.errors.message}
										</div>
									)}
									{rest.success.message && (
										<div className='alert alert-success'>
											{rest.success.message}
										</div>
									)}
									<CardTitle className='text-center' tag='h3'>
										Contact Us
									</CardTitle>
									<Row>
										<Col className='ml-auto' md='5'>
											<CardBody>
												<div className='info info-horizontal'>
													<div className='icon icon-info'>
														<i className='nc-icon nc-pin-3' />
													</div>
													<div className='description'>
														<h4 className='info-title'>Location</h4>
														<p>
															Calgary AB <br /> Canada
														</p>
													</div>
												</div>
												<div className='info info-horizontal'>
													<div className='icon icon-danger'>
														<i className='nc-icon nc-badge' />
													</div>
													<div className='description'>
														<h4 className='info-title'>Reach out</h4>
														<p>
															For support purposes <br /> support@weciny.com{' '}
															<br />
															To join our team <br /> hr@weciny.com
														</p>
													</div>
												</div>
											</CardBody>
										</Col>
										<Col className='mr-auto' md='5'>
											<Form
												onSubmit={handleSubmit}
												id='contact-form'
												method='post'
												role='form'>
												<CardBody>
													<FormGroup className='label-floating'>
														<label className='control-label'>Name</label>
														<Input
															labelText='Your Name'
															id='name'
															type='text'
															name='name'
															onChange={(e) => {
																setName(e.target.value);
															}}
															value={name}
															required
														/>
													</FormGroup>
													<FormGroup className='label-floating'>
														<label className='control-label'>
															Email address
														</label>
														<Input
															id='email'
															type='email'
															name='email'
															value={email}
															onChange={(e) => {
																setEmail(e.target.value);
															}}
															required
														/>
													</FormGroup>
													<FormGroup className='label-floating'>
														<label className='control-label'>
															Your Phone Number
														</label>
														<Input
															labelText='Phone'
															id='phone'
															type='number'
															name='phone'
															value={phone}
															onChange={(e) => {
																setPhone(e.target.value);
															}}
														/>
													</FormGroup>
													<FormGroup className='label-floating'>
														<Input
															labelText='Your Message'
															id='text'
															name='message'
															placeholder='Message'
															type='textarea'
															rows='6'
															value={text}
															onChange={(e) => {
																setText(e.target.value);
															}}
															required
														/>
													</FormGroup>
													<Row>
														<Col md='6'></Col>
														<Col md='6'>
															<Button
																className='pull-right'
																color='primary'
																type='submit'>
																Send Message
															</Button>
														</Col>
													</Row>
												</CardBody>
											</Form>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				{/* ********* END CONTACT US 1 ********* */}
				<Footer />
			</div>
		</>
	);
}

export default SectionContactUs;
