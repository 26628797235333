import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Header from './Sections/SectionHeader';
import Footer from '../Components/Footer';
import SectionProduct from './Sections/SectionProduct';
import { loadProduct } from '../../store/actions/product';
import { loadShoppingCart } from '../../store/actions/purchase';
import './index.css'; // Import custom styles for additional CSS

const ProductPage = (rest) => {
	const dispatch = useDispatch();

	const product = useSelector((state) => state.theProduct);
	const cartItems = useSelector((state) => state.shoppingCart);

	const { productId } = useParams();

	useEffect(() => {
		if (productId) {
			dispatch(loadProduct(productId)).catch((err) => {
				console.error('Failed to fetch business data:', err);
			});
		}
	}, [productId, dispatch]);

	useEffect(() => {
		dispatch(loadShoppingCart()).catch((err) => {
			console.error('Failed to fetch cart data:', err);
		});
	}, [dispatch]);
	// Wait until the product and business data are loaded
	if (!product) {
		return <div>Loading...</div>; // You can show a loading spinner here
	}
	// Find the quantity of this product in the cart
	const cartItem = cartItems.items.items?.find(
		(item) => item.productId._id === product._id
	);
	const quantityInCart = cartItem ? cartItem.quantity : 0;
	// Calculate the total number of items in the cart
	const totalItemsInCart = cartItems.items.items?.reduce(
		(total, item) => total + item.quantity,
		0
	);
	return (
		<div>
			<Navbar businessName={product.business.businessName} {...rest} />
			<Header image={product.business.image && product.business.image[0]} />
			<SectionProduct
				product={product}
				business={product.business}
				productQuantity={quantityInCart}
				totalItemsInCart={totalItemsInCart}
				{...rest}
			/>
			<Footer />
		</div>
	);
};
export default ProductPage;
