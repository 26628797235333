import axios from 'axios';
import { addError } from './errors';
import { addSuccess } from './success';
// Action Types
import { POST_FORGET, POST_RESET } from '../actionTypes';

// Action Creators
export const forgetMessage = (message) => ({
	type: POST_FORGET,
	message,
});

export const resetMessage = (message) => ({
	type: POST_RESET,
	message,
});

// API call actions
export const postForgot = (email) => async (dispatch) => {
	try {
		const res = await axios.post(`/api/auth/forgot`, { email });
		dispatch(forgetMessage(res.data.message));
		dispatch(
			addSuccess(
				'If there is an account associated with the email you will receive an email with a reset link!!'
			)
		);
	} catch (err) {
		dispatch(addError(err?.response?.data?.message || err.message));
	}
};

export const postReset = (token, data) => async (dispatch) => {
	try {
		const res = await axios.post(`/api/auth/reset/${token}`, data);
		dispatch(resetMessage(res.data.message));
	} catch (err) {
		dispatch(addError(err?.response?.data?.message || err.message));
	}
};
