import { ADD_SUCCESS, REMOVE_SUCCESS } from '../actionTypes';

// Define the reducer with a clear function name
const success = (state = { message: null }, action) => {
	switch (action.type) {
		case ADD_SUCCESS:
			return { ...state, message: action.success };
		case REMOVE_SUCCESS:
			return { ...state, message: null };
		default:
			return state;
	}
};

export default success;
